import React, {useState} from 'react';

import { Grid, 
        Typography, 
        Button, 
        Stepper, 
        Step, 
        StepLabel, 
        StepContent, 
        Box, 
        TextField, 
        IconButton,
       } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HelpIcon from '@mui/icons-material/Help';
import { useDispatch, useSelector } from 'react-redux';
import { updateScenarioProfile, updateNodeData, setEditorScenarioUI } from './editorSlice';

import { useAuth0 } from "@auth0/auth0-react";

const EditorWizard = (props) => {

  const { user } = useAuth0();
  const activeStep = props.activeStep;
  const setActiveStep = props.setActiveStep;
  const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
  const dispatch = useDispatch();

  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [learningObjectives, setLearningObjectives] = useState(scenarioProfile.learningNotes["text"]);

  const learningObjectiveLabel = {
    0: "First learning objective",
    1: "Second learning objective",
    2: "Third learning objective"
  };

  const learningObjectiveValue = {
    0: "E.g. A company's shares must not be allotted at a discount. ",
    1: "E.g. A company can issue partly-paid shares, but it must be entitled to receive the full amount.",
    2: "E.g. A public limited company must have a share capital of at least £50,000."
  };

  const [scenarioName, setScenarioName] = useState(scenarioProfile.scenarioName);
  const [scenarioShortDescription, setShortDescription] = useState(scenarioProfile.scenarioShortDescription);
  const [scenarioLongDescription, setLongDescription] = useState(scenarioProfile.scenarioLongDescription);

  const handleLearningObjectives = (event, idx) => {
    setLearningObjectives({
      ...learningObjectives,
      [idx]: event.target.value,
    })
  };

  const addLearningObjective = () => {
    setLearningObjectives({
      ...learningObjectives,
      [Object.keys(learningObjectives).length]: ""
    })
  }

  const removeLearningObjective = () => {
    setLearningObjectives(current => {
        const {[Object.keys(learningObjectives).length-1]: text, ...rest} = current
        return rest
    });
  };

  const handleScenarioNameChange = (event) => {
    setScenarioName(event.target.value);
  };

  const handleShortDescriptionChange = (event) => {
      setShortDescription(event.target.value);
  };

  const handleLongDescriptionChange = (event) => {
      setLongDescription(event.target.value);
  };

  const handleDescriptionStep = () => {
    handleNext();

    dispatch(updateScenarioProfile({
      scenarioName: scenarioName,
      scenarioShortDescription: scenarioShortDescription,
      scenarioLongDescription: scenarioLongDescription,
    }));

    const updateData = {
      nodeId: "1",
      data: {
          scenarioTitle: scenarioName,
          scenarioSubTitle: scenarioShortDescription
      }
    };

    dispatch(updateNodeData(updateData));
  }

  const showEditorGuide = () => {
    dispatch(setEditorScenarioUI({
      "viewResources": true,
      "viewHints": true,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
    }));
  }

  return (
      <Grid item style={{width: "100%", padding: 30}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"step1"}>
          <StepLabel>
            Scenario setup
          </StepLabel>
          <StepContent>
            
            <Typography paragraph>Complete each step in this setup process to generate a wireframe scenario.</Typography>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button
                  disabled={true}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={"step2"}>
          <StepLabel>
            Scenario details
          </StepLabel>
          <StepContent>
            <Grid container style={{maxWidth: 600}}>
              <Grid item style={{width: "100%"}}>
              <Typography paragraph>Give your scenario a name and description.</Typography>
              <Typography paragraph>You can always changes these later.</Typography>
              </Grid>
              <Grid item style={{width: "100%"}}>
              <form autoComplete='off'>
              <TextField
                  id="outlined-multiline-static"
                  label="Scenario name"
                  value={scenarioName}
                  onChange={handleScenarioNameChange}
                  variant="filled"
                  fullWidth
                  sx={{marginBottom: "15px"}}
              />
              <TextField
                  id="outlined-multiline-static"
                  label="Short description"
                  value={scenarioShortDescription}
                  onChange={handleShortDescriptionChange}
                  variant="filled"
                  fullWidth
                  sx={{marginBottom: "15px"}}
              />
              <TextField
                  id="outlined-multiline-static"
                  label="Long description"
                  value={scenarioLongDescription}
                  onChange={handleLongDescriptionChange}
                  variant="filled"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{marginBottom: "15px"}}
              />
              </form>
              </Grid>
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={() => handleDescriptionStep()}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={"step3"}>
          <StepLabel>
            Learning objectives
          </StepLabel>
          <StepContent>
            <Grid container style={{maxWidth: 600}}>
              <Grid item style={{width: "100%"}}>
              
              <Typography paragraph>What are the scenario learning objectives?</Typography>
              <Typography paragraph>These are two or three sentences that describe what the user should know having completed the scenario. Enter each objective in a box below.</Typography>
              </Grid>
              <Grid item style={{width: "100%"}}>
              {Object.keys(learningObjectives).map((key, idx) => (
                  <TextField
                    id="outlined-multiline-static"
                    label={learningObjectiveLabel[idx]}
                    placeholder={learningObjectiveValue[idx]}
                    value={learningObjectives[idx]}
                    onChange={(event) => handleLearningObjectives(event, idx)}
                    fullWidth
                    multiline
                    key={"LO"+idx}
                    rows={3}
                    variant="filled"
                    style={{marginBottom: 15}}
                  />
                )
              )}
              </Grid>
              <Grid container style={{width: "100%", justifyContent: "center"}}>
                    <Grid item>
                    <IconButton disabled={Object.keys(learningObjectives).length >= 3} onClick={() => addLearningObjective()}><AddCircleOutlineIcon /></IconButton>
                    </Grid>
                    <Grid item>
                    <IconButton disabled={Object.keys(learningObjectives).length < 2 || Object.keys(learningObjectives).length > 3} onClick={() => removeLearningObjective()}><RemoveCircleOutlineIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={() => {handleNext(); dispatch(updateScenarioProfile({
                    learningNotes: {
                      text: learningObjectives
                    },
                    }))}}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={"step4"}>
          <StepLabel>
            Customisation
          </StepLabel>
          <StepContent>
            <Typography>You have now completed the setup process. Click Finish to continue editing your wireframe scenario.</Typography>
            <Typography>You can access the Editor Guide at any point by clicking <IconButton onClick={() => showEditorGuide()}><HelpIcon color="primary" fontSize="small" /></IconButton> or selecting "Editor Guide" in the scenario preview window.</Typography>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={() => {handleNext(); dispatch(updateScenarioProfile({
                    author: {
                      ID: user.sub,
                      email: user.email
                    },
                  }))}}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Finish
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      </Grid>
  )
};

export default EditorWizard;

/*

  const [selectedTask, setSelectedTask] = useState("");

  const handleTaskSelect = (event) => {
    setSelectedTask(event.target.value);
  };

<Step key={"step4"}>
          <StepLabel>
            Template
          </StepLabel>
          <StepContent>
            <Typography paragraph>Select a scenario template. You'll be able to customise this later.</Typography>
            <Grid item style={{width: "100%"}}>
              <Grid item style={{width: 300, paddingBottom: 15}}>
                <FormControl fullWidth variant="standard">
                <InputLabel id="task-select-label">Task template</InputLabel>
                <Select
                  labelId="task-select-label"
                  id="task-select"
                  value={selectedTask}
                  label="Task template"
                  onChange={handleTaskSelect}
                >
                  <MenuItem value={1}>Review a steps plan</MenuItem>
                  <MenuItem value={2}>Review a mark-up</MenuItem>
                  <MenuItem value={3}>Review other documents</MenuItem>
                </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>

*/