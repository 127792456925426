import React, {useState, useEffect} from 'react';

import { updateNodeData, updateEdgeData, deleteNode, setEditable, addNewNodeOnDrag, updateScenarioProfile, setSaveError, setNodeAI } from './editorSlice';

import { Typography, Grid, IconButton, Modal, Button, Tooltip, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import RepeatIcon from '@mui/icons-material/Repeat';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ModeCommentIcon from '@mui/icons-material/ModeComment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';

import KnotOption from './KnotOption';
import KnotText from './KnotText';
import KnotFeedback from './KnotFeedback';

import ActionNodeContainer from './action_node/ActionNodeContainer';
import AINodeContainer from './ai_node/AINodeContainer';

import { useDispatch, useSelector } from 'react-redux';
import KnotComments from './KnotComments';

import { useAuth0 } from "@auth0/auth0-react";
import {v4 as uuidv4} from 'uuid';

const ScenarioKnot = (props) => {

    const editable = useSelector((state) => state.editorData.editable)
    const knotData = props.data
    const nodeId = props.nodeId;
    const { user } = useAuth0();

    const [collapse, setCollapse] = useState(true);
    const [nodeMode, setNodeMode] = useState(knotData.hasOwnProperty("mode") ? knotData.mode : "unset");

    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile)
    const userAccessToken = useSelector((state) => state.appData.accessToken);
    
    const edges = useSelector((state) => state.editorData.edges);
    const edgeIDCount = useSelector((state) => state.editorData.edgeID);
    const nodes = useSelector((state) => state.editorData.nodes);
    const nodeIDCount = useSelector((state) => state.editorData.nodeID);
    const nodePosition = nodes.filter((node) => node.id === nodeId).length === 1 ? nodes.filter((node) => node.id === nodeId)[0]["position"] : null
    const nodeEdges = edges.filter((edge) => edge.source === nodeId);

    const nodeActions = knotData?.nodeActions
    const nodeAI = knotData?.nodeAI

    const [changesMade, setChangesMade] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState(false);

    const [knotText, setKnotText] = useState(knotData.text);
    const [knotFormat, setKnotFormat] = useState(knotData.format);
    const [knotComments, setKnotComments] = useState(knotData.knotComments);
    const [newKnotComment, setNewKnotComment] = useState("");
    const [viewComments, setViewComments] = useState(false);
    
    const [optionData, setOptionData] = useState(null);
    const [expandFeedback, setExpandFeedback] = useState(false);
    const [blockWarning, setBlockWarning] = useState({
        noOptions: false,
        blankOption: false,
        blankText: false,
        blankFeedback: false,
        borderColor: "#cccccc",
    });
    
    const formatMap = {
        _narrative_: {label: "Narrative", color: "#9e9e9e"},
        _speechYour_: {label: "Your dialogue", color: "#009688"},
        _speechOther_: {label: "Dialogue", color: "#3f51b5"},
        _factMinor_: {label: "Alert", color: "#ffc107"},
        _documentThumbnailNeutral_: {label: "Thumbnail", color: "#9e9e9e"},
    } 
    
    const handleCollapse = () => {
        dispatch(setEditable(true));
        setExpandFeedback(false);
        setViewComments(false);
        setCollapse(true);
        const updateData = {
            nodeId: nodeId,
            data: {
                text: knotText,
                format: knotFormat,
                knotComments: knotComments,
                mode: nodeMode,
            }
        };
        dispatch(updateNodeData(updateData));
        dispatch(updateEdgeData(optionData));
        setSaveTrigger(true);
        setOptionData({})
    };

    const handleDelete = () => {
        dispatch(setEditable(true));
        setCollapse(true);
        setExpandFeedback(false);
        setViewComments(false);
        const nodeData = {
            deletedNodeID: nodeId,
        }

        dispatch(deleteNode(nodeData));
        setChangesMade(true);
        setSaveTrigger(true);
    };

    const handleOpen = (action) => {
        setCollapse(false)
        dispatch(setEditable(false))
        if (action === "comment") {
            setViewComments(true)
        } else {
            // always open comments if there are some (live comments; comments that have not been deleted)
            if (knotComments && Object.keys(knotComments).filter((key) => (knotComments[key]["status"] !== "deleted")).length > 0) {
                setViewComments(true)
            };
        };

        // get option data

        var initialOptionData = {}
        nodeEdges.forEach((option, index) => {
        initialOptionData[index] = {
            text: option.label,
            format: option.data.format,
            feedbackText: option.data.feedbackText,
            feedbackType: option.data.feedbackType,
            id: option.id,
            target: option.target
        }
        });
        setOptionData(initialOptionData);
    };

    const addKnotText = () => {
        const newUUID = uuidv4();
        setKnotText({
            ...knotText,
            [newUUID]: ""
        });
        setKnotFormat({
            ...knotFormat,
            [newUUID]: "_speechOther_"
        });
        setChangesMade(true);
    };

    const removeKnotText = (textIndex) => {
        setKnotText(current => {
            const {[textIndex]: text, ...rest} = current
            return rest
        });
        setKnotFormat(current => {
            const {[textIndex]: format, ...rest} = current
            return rest
        });
        setChangesMade(true);
    };

    const dispatch = useDispatch();

    const getNewNodePosition = () => {
        const yPosition = nodePosition["y"] + 200
        const xPosition = nodePosition["x"] - 400 + 400*(Object.keys(optionData).length)
        return({x: xPosition, y: yPosition})
    };

    const addKnotComment = () => {
        const dateKey = Date.now();
        var knotData = {...knotComments};
        knotData[dateKey] = {
            text: newKnotComment,
            status: "open",
            user: user.email,
        };

        setKnotComments(knotData);
        setNewKnotComment("");

        // why do these need to be included in the comment processing?
        // text: knotText,
        // format: knotFormat,

        const updateData = {
            nodeId: nodeId,
            data: {
                mode: nodeMode,
                knotComments: knotData,
            }
        };
        dispatch(updateNodeData(updateData));
        setChangesMade(true);
    };

    const handleCommentChange = (event) => {
        setNewKnotComment(event.target.value);
        setChangesMade(true);
    };

    const getCommentIconColour = () => {
        if (!editable) {
            // apply disabled color (missing because of override)
            return grey[400]
        } else {
            if (knotComments && Object.keys(knotComments).filter((key) => (knotComments[key]["status"] === "open")).length > 0) {
                // highlight blocks with open comments orange
                return "#ef6c00"
            } else {
                // otherwise normal icon colour
                return grey[600]
            }
        };
    };

    const writeScenario = async (scenarioData) => {
        try {
            const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });
            const responseData = await scenarioResponse.json()
            if (responseData.modified > 0 || responseData.upsertID !== "") {
                console.log("save success")
            } else {
                dispatch(setSaveError(true));
            };
            }
        catch(e) {
            dispatch(setSaveError(true));
            console.log(e)
        }
    };

    // TODO: push AI block out into own component.

    const toggleNodeMode = () => {

        const modeMap = {
            "unset": "action",
            "block": "action",
            "action": "AIblock",
            "AIblock": "block"
        }

        const newMode = modeMap[nodeMode]
        setNodeMode(newMode)

        // Hmmm, if nodeAI is undefined in node data, set defaults. Probably a clearer way of doing this than !nodeAI.
        if (newMode === "AIblock" && !nodeAI) {
            dispatch(setNodeAI({
                nodeID: nodeId,
                nodeAI: {
                    type: "explanation",
                    promptInputs: {
                        topic: "",
                        userAnalysis: "",
                        correctAnalysis: "",
                        correctExplanation: ""
                    }
                } 
            }))
        }
    };

    useEffect(() => {
        
        var initialOptionData = {}
        nodeEdges.forEach((option, index) => {
        initialOptionData[index] = {
            text: option.label,
            format: option.data.format,
            feedbackText: option.data.feedbackText,
            feedbackType: option.data.feedbackType,
            id: option.id,
            target: option.target
        };
        });
        
        if (!collapse) {

            // when editing, sync option state (need this to pick up new blocks)

            setOptionData({
                ...initialOptionData,
                ...optionData,
            });
        }
        
        // Do validation. Option text validation not real time. Think about colours. Tie up other warnings. "#e91e63"

        var blankText = false
        var blankOption = false
        var noOptions = false
        var blankFeedback = false
        var borderStyle = "1px solid #cccccc"

        if (nodeMode === "action") {
            // Action block warnings

            // Catch initialisation with no nodeAction property
            if (nodeActions) {
                // No actions set
                if (Object.keys(nodeActions).length === 0) {
                    borderStyle = "3px solid #ef6c00"
                }
    
                // Empty action
                for (let actionUUID of Object.keys(nodeActions)) {
                    // Check if either (data empty & not an always condition) or (action data empty)
                    // Won't highlight non-empty but not set
                    if (((Object.keys(nodeActions[actionUUID]["condition"]["data"]).length === 0) && (nodeActions[actionUUID]["condition"]["type"] !== "always")) || Object.keys(nodeActions[actionUUID]["action"]["data"]).length === 0) {
                        borderStyle = "3px solid #ef6c00"
                    }
                }
            } else {
                borderStyle = "3px solid #ef6c00"
            }

            // No connections
            if (Object.keys(initialOptionData).length === 0) {
                borderStyle = "3px solid #ef6c00"
            };

        } else {
        if (nodeMode === "AIblock") {

            // Empty inputs
            if (nodeAI.type === "questions" || nodeAI.type === "explanation") {
            if (Object.keys(nodeAI.promptInputs).some(
                key => {
                    // Ignoring optional keys
                    if (key === "evaluationInstructions" || key === "feedbackExplanation") {
                      return false;
                    }
                    // Check if the value is effectively empty (including strings that contain only whitespace)
                    const value = nodeAI.promptInputs[key].trim();
                    return value === "";
                  }
            )) {
                borderStyle = "3px solid #ef6c00"
            }
        } else if (nodeAI.type === "issuespot") {
            // Issue spot
            if (Object.values(nodeAI.issues).some(item => item.issueName === "" || item.issueDescription === "")) {
                borderStyle = "3px solid #ef6c00"
                }
            }

            // No connections
            if (Object.keys(initialOptionData).length === 0) {                
                borderStyle = "3px solid #ef6c00"
            };

        } else {

        // Normal block warnings
        if (Object.values(knotText).some(val => val === "")) {
                blankText = true
                borderStyle = "3px solid #ef6c00"
        };
        
        if (Object.keys(initialOptionData).length === 0) {
            noOptions = true
            borderStyle = "3px solid #ef6c00"
        };

        Object.keys(initialOptionData).forEach((key) => {
            if (initialOptionData[key]["text"] === "" && initialOptionData[key]["target"] !== "3" && initialOptionData[key]["format"] !== "passThrough") {
                blankOption = true
                borderStyle = "3px solid #ef6c00"
            };
        });

        Object.keys(initialOptionData).forEach((key) => {

            const targetNode = nodes.filter((node) => node.id === initialOptionData[key]["target"])[0]
            if (targetNode.data?.mode !== "AIblock") {
                if ((initialOptionData[key]["feedbackType"] === "correct" || 
                initialOptionData[key]["feedbackType"] === "incorrect") && 
                    (initialOptionData[key]["feedbackText"] === "")) {
                        blankFeedback = true
                        borderStyle = "3px solid #ef6c00"
                };
            };
        });
        }
    }
        
        setBlockWarning({
            noOptions: noOptions,
            blankOption: blankOption,
            blankText: blankText,
            blankFeedback: blankFeedback,
            borderStyle: borderStyle,
        });

        if (changesMade && saveTrigger) {

            // looks ok, but should do some thorough testing.

            const editorData = {
                nodes: nodes,
                edges: edges,
                nodeID: nodeIDCount,
                edgeID: edgeIDCount
            };
            var scenarioData = {
                ...scenarioProfile, 
                editorData: editorData,
                lastModified: Date.now(),
            };
            if (scenarioData.scenarioID === "") {
                const newUUID = uuidv4();
                scenarioData.scenarioID = newUUID;
                const profileUpdate = {scenarioID: newUUID}
                dispatch(updateScenarioProfile(profileUpdate))
            };
            writeScenario(scenarioData);
            setChangesMade(false);
            setSaveTrigger(false);
        };
    },[edges, knotText, changesMade, saveTrigger])

    return (
      <Grid container style={{width: 350}}>
        <Grid item style={{width: "100%", border: collapse ? `${blockWarning["borderStyle"]}` : "3px solid #3f51b5", borderRadius: 10, padding: collapse ? blockWarning["borderStyle"] === "3px solid #ef6c00" ? "7px" : "9px" : "7px", backgroundColor: "#ffffff"}}>
            <Grid container style={{width: "100%", justifyContent: "space-between"}}>
            <Typography variant="body2" color="textSecondary">Scenario {nodeMode === "action" ? "rule" : nodeMode === "AIblock" ? "dynamic block" : "block"} (ID: {nodeId})</Typography>
            <Grid item>
                <Grid container>
                <IconButton disabled={!editable} onClick={() => {handleOpen("edit")}} className="nodrag">
                    <Tooltip title="Edit" placement='top'>
                    <EditIcon fontSize="small"/>
                    </Tooltip>
                </IconButton>
                <IconButton disabled={!editable} onClick={() => {handleOpen("comment")}} className="nodrag">
                    <Tooltip title="Comment" placement='top'>
                    <ModeCommentIcon fontSize="small" sx={{color: getCommentIconColour()}} />
                    </Tooltip>
                </IconButton>
                <IconButton disabled={nodeId === "2" || !editable} onClick={() => handleDelete()} className="nodrag">
                    <Tooltip title="Delete" placement='top'>
                    <DeleteIcon fontSize="small"/>
                    </Tooltip>
                </IconButton>
                </Grid>
            </Grid>
            </Grid>
            <Grid item style={{width: "100%"}}>
            <Grid item>
                {Object.keys(knotText).map((key, index) => (
                <Typography key={index} variant="body2" style={{color: formatMap[knotFormat[key]]["color"]}}>{knotText[key]}</Typography>
                ))}
            </Grid>
            {collapse ?
            null :
            <Modal
            open={!collapse}
            onClose={() => handleCollapse()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            >
            <Grid item 
                sx={{
                    width: (viewComments || nodeMode === "action" || nodeMode === "AIblock") ? "850px" : "500px", 
                    padding: "15px",
                    position: "absolute", 
                    top: '30%', 
                    left: '50%', 
                    transform: 'translate(-50%, -30%)',
                    outline: 0,
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    maxHeight: "calc(100vh - 20%)",
                    overflow: "auto",
                    '&::-webkit-scrollbar': {
                        width: "20px",
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: "#ffffff",
                        borderTopRightRadius: 10,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: "#d6dee1",
                        borderRadius: "20px",
                        border: "6px solid transparent",
                        backgroundClip: "content-box",
                      },
                      '&::-webkit-scrollbar-thumb-hover': {
                        backgroundColor: "#a8bbbf",
                      },
                }} 
            tabIndex={-1}>
            <Grid container sx={{width: "100%", flexWrap: "nowrap"}}>
            <Grid item sx={{minWidth: viewComments ? "500px" : "100%"}}>

            <Grid container sx={{width: "100%", justifyContent: "space-between", paddingBottom: "5px"}}>
                <Typography variant="body1" sx={{paddingTop: "6px"}}>Scenario {nodeMode === "action" ? "rule" : nodeMode === "AIblock" ? "dynamic block" : "block"}</Typography>
                {viewComments ?
                null
                :
                <Grid item>
                <Grid container>
                    <IconButton onClick={() => {toggleNodeMode()}} className="nodrag">
                        <Tooltip title="Change block type" placement='top'>
                        <RepeatIcon fontSize="small"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => {setViewComments(true)}} className="nodrag">
                        <Tooltip title="Comment" placement='top'>
                        <ModeCommentIcon fontSize="small"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => handleCollapse()} className="nodrag">
                        <Tooltip title="Save" placement="top">
                        <DoneIcon fontSize="small"/>
                        </Tooltip>
                    </IconButton>
                </Grid>
                </Grid>
                }
            </Grid>
            {nodeMode === "action" ? 
            <ActionNodeContainer 
                setChangesMade={setChangesMade}
            />
            :
            nodeMode === "AIblock" ? 
            <AINodeContainer 
                setChangesMade={setChangesMade}
            />
            :
            <>
            <Grid item sx={{paddingBottom: "3px"}}>
                <Typography variant='body2' color="textSecondary" sx={{display: "inline"}}>Block content</Typography>{blockWarning["blankText"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Empty block</Typography> : null}
            </Grid>
            <Grid item sx={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "3px", marginBottom: "15px"}}>
            <Grid item>
                {Object.keys(knotText).map((key, index) => (
                    <KnotText 
                        textIndex={key} 
                        knotText={knotText} 
                        setKnotText={setKnotText}
                        knotFormat={knotFormat}
                        setKnotFormat={setKnotFormat}
                        removeKnotText={removeKnotText}
                        setChangesMade={setChangesMade}
                        key={"text" + key }
                    />
                ))}
            </Grid>
            <Grid item style={{width: "100%"}}>
                <Grid container style={{width: "100%", justifyContent: "center", alignContent: "center"}}>
                    <Grid item>
                    <IconButton disabled={Object.keys(knotText).length >= 4} onClick={() => addKnotText()} className="nodrag"><AddCircleOutlineIcon /></IconButton>
                    </Grid>
                    <Grid item>
                    <IconButton disabled={Object.keys(knotText).length < 2 || Object.keys(knotText).length > 4} onClick={() => removeKnotText(Object.keys(knotText).length-1)} className="nodrag"><RemoveCircleOutlineIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            {(Object.keys(optionData).length === 1 && optionData[0].target === '3') ? null : 
            <>
            <Typography variant="body2" color="textSecondary" sx={{display:"inline"}}>Block options</Typography>{blockWarning["blankOption"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Empty option</Typography> : null}{blockWarning["noOptions"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: No options. Add a new option or connect to an existing block.</Typography> : null}
            <Grid item sx={{backgroundColor: "#fafafa", padding: "0px 10px 10px 10px", borderRadius: "3px", marginBottom: "15px"}}>
                {Object.keys(optionData).map((key, index) => {
                    // no option input for scenario completion
                    if (optionData[index]["target"] !== '3') {
                        return(
                        <KnotOption 
                            optionIndex={index} 
                            optionData={optionData} 
                            setOptionData={setOptionData}
                            setChangesMade={setChangesMade}
                            key={"option" + index}
                        />)
                        }
                    })
                }
                <Grid container sx={{width: "100%", justifyContent: "center", paddingTop: "10px"}}>
                <Button disabled={Object.keys(optionData).length > 4} size="small" variant="outlined" onClick={() => dispatch(addNewNodeOnDrag({
                    position: getNewNodePosition(),
                    source: nodeId,
                }))}>Add option</Button>
                </Grid>
            </Grid>
            </>
            }
            {Object.keys(optionData).length > 1 || (Object.keys(optionData).length === 1 && optionData[0].target !== '3') ? 
            <Grid item style={{width: "100%"}}>
            <Grid container>
                <Grid item sx={{paddingTop: "5px"}}>
                <Typography variant="body2" color="textSecondary" sx={{display: "inline"}}>Feedback</Typography>{blockWarning["blankFeedback"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Empty feedback</Typography> : null}
                </Grid>
                <Grid item>
                <IconButton onClick={() => setExpandFeedback(!expandFeedback)} className="nodrag">{expandFeedback ? <ExpandLessIcon fontSize='small'/> : <ExpandMoreIcon fontSize='small'/>}</IconButton>
                </Grid>
            </Grid>
            </Grid>
            : null}
            {expandFeedback ? 
            <Grid item sx={{backgroundColor: "#fafafa", padding: "0px 10px 10px 10px", borderRadius: "3px", marginBottom: "15px"}}>
            {Object.keys(optionData).map((key, index) => {
                // no option input for scenario completion
                if (optionData[index]["target"] !== '3') {
                    return(
                    <KnotFeedback 
                        optionIndex={index} 
                        optionData={optionData} 
                        setOptionData={setOptionData}
                        setChangesMade={setChangesMade}
                        key={"optionFeedback" + index}
                    />)
                    }
                })
            }
            </Grid>
            :
            null
            }
            </>
            }
            </Grid>

            {viewComments ?
            <Grid item sx={{flexGrow: 1}}>
            <Grid container sx={{paddingLeft: "10px", flexDirection: "column"}}>
                <Grid item sx={{width: "100%"}}>
                <Grid container sx={{width: "100%", justifyContent: "space-between", paddingBottom: "5px"}}>
                    <Typography variant="body1" sx={{paddingTop: "6px"}}>Comments</Typography>
                    <IconButton onClick={() => handleCollapse()} className="nodrag">
                        <Tooltip title="Save" placement="top">
                        <DoneIcon fontSize="small"/>
                        </Tooltip>
                    </IconButton>
                </Grid>
                </Grid>
                <KnotComments 
                    nodeID={nodeId} 
                    knotComments={knotComments} 
                    setKnotComments={setKnotComments} 
                    setChangesMade={setChangesMade}
                />
                <Grid item sx={{width: "100%"}}>
                    <form autoComplete='off' onSubmit={e => { e.preventDefault()}}>
                    <TextField
                    id="outlined-multiline-static"
                    value={newKnotComment}
                    onChange={handleCommentChange}
                    variant="filled"
                    size="small"
                    fullWidth
                    multiline
                    rows={2}
                    className="nodrag"
                    />
                    </form>
                    <Grid container sx={{width: "100%", justifyContent: "center", paddingTop: "3px"}}>
                    <Button size="small" onClick={() => addKnotComment()}>Add comment</Button>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            :
            null
            }
            </Grid>
            </Grid>
            </Modal>
            }
            </Grid>
        </Grid>
      </Grid>
    )
};
export default ScenarioKnot;