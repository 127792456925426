import { createSlice } from '@reduxjs/toolkit';
import { Compiler, CompilerOptions } from "inkjs";
import { applyNodeChanges, applyEdgeChanges, addEdge, updateEdge } from 'reactflow';

const defaultScenarioInkString = `
    VAR points_received = 1
    VAR incorrect_points = 0
    VAR total_points = 1
    New Scenario_heading_
    A new legal topic_subHeading_
    * [Begin] -> background_knot
    === background_knot
    Use the reload button in the top right of this screen to play your own scenario_narrative_
    -> final_knot
    === maxims
    _maxims_
    Set feedback in the scenario knots.
    -> DONE
    === feedback
    _feedback_
    Set the learning notes in the scenario settings.
    -> DONE
    === final_knot
    {points_received == total_points:
    <-maxims
    }
    {points_received != total_points:
    <-feedback
    }
    * [Complete] -> complete
    === complete
    -> END
`

const defaultScenarioJson = {
  feedbackKnot: {
    data: [],
  },
  nodes: [{
    id: '1',
    data: { 
      scenarioTitle: "New Scenario",
      scenarioSubTitle: "A new legal topic", },
    position: { x: 200, y: 50 },
    type: 'titleNode',
  },
  {
    id: '2',
    data: {text: {0: 'Introduction'}, format: {0: '_narrative_'}},
    position: { x: 150, y: 200 },
    type: 'scenarioNode',
  },
  {
    id: '3',
    data: {label: "Complete"},
    position: { x: 200, y: 600},
    type: 'completeNode',
  }],
  edges: [
    { id: '1', source: '1', target: '2', label: 'Begin', data: {format: "action", scoring: false}},
  ],
  nodeID: 4,
  edgeID: 2,
  scenarioProfile: {
    scenarioID: "",
    scenarioName: "New Scenario",
    pageID: "",
    scenarioShortDescription: "A new legal topic",
    scenarioLongDescription: "",
    scenarioELO: 700,
    learningNotes: {
      text: {0: ""},
    },
    scenarioPlayTime: 2,
    playCount: 0,
    scenarioSubTopics: [],
    scenarioAssets: {},
    inkString: "",
    scenarioEditable: true,
    UIOrder: 1,
    status: "unpublished",
    sharing: "linkSharing",
    RBAC: ["SM"],
    author: {
      ID: "",
      email: ""
    }
  },
  editable: true,
  editorScenarioUI: {
    "viewResources": false,
    "viewHints": false,
    "viewDocuments": false,
    "viewPrimarySources": false,
    "viewOutline": false,
    "selectedDocumentID": "",
    "documentPlayMode": "play"
  },
  inkString: "",
}

export var ink = new Compiler(defaultScenarioInkString).Compile();

export const gameLoop = () => {
    const sceneText = [];
    let currentTags = [];
     
    while (ink.canContinue) {
      
      sceneText.push(ink.Continue());
      currentTags = currentTags.concat(ink.currentTags);
    }
  
    const { currentChoices, variablesState } = ink;
    const parseChoices = getChoices(currentChoices);
    const gameVars = getGlobalVars(variablesState);
  
    if (!ink.canContinue && !currentChoices.length)
      return {
        tags: {},
        ending: true
      }
    return {
      globals: gameVars,
      tags: getTags(currentTags),
      parseChoices,
      sceneText,
      ending: false
    };
};

export const getGlobalVars = (variablesState) => {
  const globalVars = variablesState._globalVariables
  const gameVars = {};

  // you have to work harder to extract a list from an ink LIST
  // this takes the list item names that are currently "on" and puts them in an array in globals (with the list name as the name)

  const unPackMap = (map, key) => {
    const listVals = []
    map.forEach((val, key) => {
      const item = JSON.parse(key)
      listVals.push(item.itemName)
    })
    gameVars[key] = listVals;
  };

  // get the variables and put them in an object

  globalVars.forEach(
    (val, key) => {
      if (typeof(val.value) === "object") {
        unPackMap(val.value, key)
      }
      if (typeof(val.value) !== "object") {
        gameVars[key] = val.value}
    }
  )
  return gameVars
  };


export const getTags = tags =>
    tags.reduce(
        (acc, tag) => ({ ...acc, [tag.split(": ")[0]]: tag.split(": ")[1] }),
        {}
);

export const getChoices = choices => 
  choices.reduce(
    (acc, val, idx) => ( [...acc,
        {
      "index": idx,
      "text": val.text
    }]),
    []
  );

export const editorSlice = createSlice({
  name: "editorData",
  initialState: {
    feedbackKnot: {
      data: [],
    },
    nodes: [{
      id: '1',
      data: { 
        scenarioTitle: "New Scenario",
        scenarioSubTitle: "A new legal topic", },
      position: { x: 200, y: 50 },
      type: 'titleNode',
    },
    {
      id: '2',
      data: {text: {0: 'Introduction'}, format: {0: '_narrative_'}},
      position: { x: 150, y: 200 },
      type: 'scenarioNode',
    },
    {
      id: '3',
      data: {label: "Complete"},
      position: { x: 200, y: 600},
      type: 'completeNode',
    }],
    edges: [
      { id: '1', source: '1', target: '2', label: 'Begin', data: {format: "action", scoring: false}},
    ],
    nodeID: 4,
    edgeID: 2,
    scenarioProfile: {
      scenarioID: "",
      scenarioName: "New Scenario",
      pageID: "",
      scenarioShortDescription: "A new legal topic",
      scenarioLongDescription: "",
      scenarioELO: 700,
      learningNotes: {
        text: {0: ""},
      },
      scenarioPlayTime: 2,
      playCount: 0,
      scenarioSubTopics: [],
      scenarioAssets: {},
      inkString: "",
      scenarioEditable: true,
      UIOrder: 1,
      status: "unpublished",
      sharing: "linkSharing",
      RBAC: ["SM"],
      author: {
        ID: "",
        email: ""
      }
    },
    editable: true,
    editorScenarioUI: {
      "viewResources": false,
      "viewHints": false,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
      "selectedDocumentID": "",
      "documentPlayMode": "play"
    },
    inkString: "",
    parsingErrors: {
      inkErrors: [],
      reflexErrors: []
    },
    playerError: {
      runtimeError: false,
      message: ""
    },
    saveError: false,
    score: {
      points_recevied: 0,
      total_points: 0
    },
    feedbackContent: [],
    feedbackType: "",
    ending: false,
    fullText: [],
    playerDocument: [],
    AIMessageStack: {},
    AIPendingMessage: false,
    ...gameLoop()
    },
  reducers: {
    loadScenarioIntoInk: (state) => {
        var inkString = ""
        var scenarioPoints = 0
        state.parsingErrors.reflexErrors = []

        const cleanString = (str) => {
          return str 
            .replace(/\r?\n|\r/g, '')        // Remove line breaks
            .replace(/^\* /, '')             // Remove '* ' from the start
            .replace(/->/g, '')              // Remove '->' from the string
            .replace(/VAR/g, '')              // Remove 'VAR' from the string
            .replace(/\{.*?\}/g, '')         // Remove '{...}' sequences
            .replace(/^~ /, '')             // Remove '~ ' from the start
            .replace(/^=+/g, '')            // Remove '=' signs from the start
            .replace(/[\[\]]/g, '');         // Remove '[' and ']' characters            
        }

        const getVariables = () => {

          var pointVariables = ""
          var outlineVariables =""
          var feedbackVariables = ""
          var AIFeedbackVariables = ""
          var documentVariables = ""
          const nodes = state.nodes
          const edges = state.edges

          if (scenarioPoints > 0) {
            pointVariables = `
            VAR points_received = 0
            VAR incorrect_points = 0
            VAR total_points = ${scenarioPoints}
            `
          } else {
            pointVariables = `
            VAR points_received = 0
            VAR incorrect_points = 0
            VAR total_points = 1
            `
          };

          // Get variables associated with normal feedback
          const feedbackEdges = edges.filter((edge) => (edge.data?.feedbackType === "correct") || (edge.data?.feedbackType === "incorrect"))
          if (feedbackEdges.length > 0) {
            for (const edge of feedbackEdges) {
              feedbackVariables = feedbackVariables + `VAR option${edge.id}Feedback = false\n`
            }
          }

          // Get variables associated with outlines
          if (state.scenarioProfile.scenarioAssets.hasOwnProperty("outline")) {
            state.scenarioProfile.scenarioAssets.outline[0]["actions"].forEach((action) => (
              outlineVariables = outlineVariables + `VAR ${action["inkLabel"]} = "tbc" \n`
            ))
          };

          // Get variables for AI feedback.
          // Has both a variable to hold the feedback and a variable to set the current feedback node (since need to set externally)
          // The currentFeedbackNode is just the knot name - didn't seem to be an easy way of getting this from the ink story object.

          const AINodes = nodes.filter((node) => node.data?.mode === "AIblock")
          if (AINodes.length > 0) {
            AIFeedbackVariables = AIFeedbackVariables + 'VAR currentFeedbackNode = ""\n'
            for (const node of AINodes) {
              let nodeVariables = ""
              if (node.data.nodeAI.type === "issuespot") {
                Object.keys(node.data.nodeAI.issues).forEach((key, idx) => {
                  nodeVariables = nodeVariables + `VAR k${node.id}issue${idx}AIEvaluation = ""\n`
                })
              } else {
                nodeVariables = `VAR k${node.id}AIFeedback = ""\n VAR k${node.id}AIEvaluation = ""\n`
              }
              AIFeedbackVariables = AIFeedbackVariables + nodeVariables
            };
          };


          // Get document variables
          // TODO: bit of a mess refreshing etc. should make it so doc data get loaded on scenario load.
          // Crashing out on deletion / creation loop too.
          // Doesn't like loading with no gold answers...

          if (state.scenarioProfile.scenarioAssets.hasOwnProperty("documents")) {
            if (state.scenarioProfile.scenarioAssets.documents[0]["type"] === "redaction") {
              if (state.scenarioProfile.scenarioAssets.documents[0].hasOwnProperty("goldAnswers")) {
                if (state.scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].length > 0) {
                  state.scenarioProfile.scenarioAssets.documents[0].goldAnswers.forEach((redaction) => {
                    documentVariables = documentVariables + `VAR ${redaction.inkLabel} = false \n`
                  })
                }
              }
            };

            if (state.scenarioProfile.scenarioAssets.documents[0]["type"] === "markup") {
              if (state.scenarioProfile.scenarioAssets.documents[0].hasOwnProperty("goldAnswers")) {
                if (state.scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].length > 0) {
                  state.scenarioProfile.scenarioAssets.documents[0].goldAnswers.forEach((markup) => {
                    documentVariables = documentVariables + `VAR ${markup.inkLabel} = "unset" \n`
                  })
                }
              }
            }
          };


          return pointVariables + outlineVariables + feedbackVariables + AIFeedbackVariables + documentVariables
        }

        const getTitleKnot = () => {
          const nodes = state.nodes
          const titleNodeIdx = nodes.findIndex((node) => node.id === '1')
          return(`
          ${cleanString(nodes[titleNodeIdx]["data"]["scenarioTitle"])}_heading_
          ${cleanString(nodes[titleNodeIdx]["data"]["scenarioSubTitle"])}_subHeading_
          _coverImage_
          * [Begin] -> k2
          `)
        };

        const getScenarioKnots = () => {
          // reset state
          var inkString = ""
          state.feedbackKnot.data = []

          const nodes = state.nodes
          const edges = state.edges

          nodes.forEach((node) => {
            
            /*
            feedback rework
            also push to feedback state an index num. sort on this num before joining
            index num is data points in edge (if set), if not use source
            when manual up / down, set index num to be +1 -1 of the item currently above
            OR
            construct an index num when read into front end (1 - x)
            and then fiddle with this / re-write changes at end (yes)
            */
            
            // Don't parse title node or completion node
            if (node.id !== '1' & node.id !== '3') {
              var textString = ""
              var optionString = ""
              
              // Handle Actions
              if (node.data.hasOwnProperty("mode") && node.data.mode === "action") {
                
                // Push actions into block
                // go through each action and set the condition logic and the action logic.
                // put everything in the text string and option string is default pass through?
                for (let actionUUID of Object.keys(node.data.nodeActions)) {
                  let conditionString = ""
                  let actionString = ""

                  // Skip any empty actions
                  if (((Object.keys(node.data.nodeActions[actionUUID]["condition"]["data"]).length > 0) || (node.data.nodeActions[actionUUID]["condition"]["type"] === "always")) && Object.keys(node.data.nodeActions[actionUUID]["action"]["data"]).length > 0) {

                    // HANDLE CONDITIONS
                    // If "always", pass through to actions.
                    if (node.data.nodeActions[actionUUID]["condition"]["type"] === "always") {
                      conditionString = "true"
                    }
  
                    /* If visitedBlock, condition is like:
                    {[not ]blockID:
                      Do some actions
                    }
                    */
                    if (node.data.nodeActions[actionUUID]["condition"]["type"] === "visitedBlock") {
                      if (node.data.nodeActions[actionUUID]["condition"]["data"]["visitedBlock"]["blockID"] !== "") {
                        let visited = node.data.nodeActions[actionUUID]["condition"]["data"]["visitedBlock"]["value"] === "visited" ? "" : "not "
                        conditionString = `${visited}k${node.data.nodeActions[actionUUID]["condition"]["data"]["visitedBlock"]["blockID"]}`
                      }
                    }
                    
                    // If userDocument, check variable state against the given value. Super annoying that redaction document went with boolean rather than strings.
                    /* So condiction is like:
                    (markup doc)
                    {my_var == "set":
                      Do some actions
                    }
                    or
                    (redact doc)
                    {not my_var:
                      Do some actions
                    }
                    */
                    if (node.data.nodeActions[actionUUID]["condition"]["type"] === "userDocument") {
                      if (node.data.nodeActions[actionUUID].condition.data?.redactionDocument) {
                        if (node.data.nodeActions[actionUUID]["condition"]["data"]["redactionDocument"]["inkLabel"] !== "") {
                          let value = node.data.nodeActions[actionUUID].condition.data.redactionDocument.value === true ? "" : "not "
                          conditionString = `${value}${node.data.nodeActions[actionUUID].condition.data.redactionDocument.inkLabel}`
                        }
                      };
                      if (node.data.nodeActions[actionUUID].condition.data?.markupDocument) {
                        if (node.data.nodeActions[actionUUID]["condition"]["data"]["markupDocument"]["inkLabel"] !== "") {
                          conditionString = `${node.data.nodeActions[actionUUID].condition.data.markupDocument.inkLabel} == "${node.data.nodeActions[actionUUID].condition.data.markupDocument.value}"`
                        }
                      };
                    }

                    /* If AIBlockEvaluation, condition is like:
                    {k[node.id]AIEvaluation == [value]:
                      Do some actions
                    }
                    */
                    if (node.data.nodeActions[actionUUID]["condition"]["type"] === "AIBlockEvaluation") {
                      if (node.data.nodeActions[actionUUID]["condition"]["data"]["AIBlockEvaluation"]["blockID"] !== "") {
                        const AIBlockEvaluation = node.data.nodeActions[actionUUID].condition.data.AIBlockEvaluation

                        // Question AI nodes eval to appropriate / not appropriate.
                        const AIEvalVar = `k${AIBlockEvaluation.blockID}AIEvaluation`
                        const conditionValue = AIBlockEvaluation.value
                        const altConditionValueMap = {
                          correct: "appropriate",
                          incorrect: "notAppropriate"
                        }
                        const altConditionValue = altConditionValueMap[conditionValue] ? altConditionValueMap[conditionValue] : ""
                        conditionString = `${AIEvalVar} == "${conditionValue}" || ${AIEvalVar} == "${altConditionValue}"`
                      }
                    }
  
                    // HANDLE ACTIONS
                    // If "goto", just divert to that block
                    if (node.data.nodeActions[actionUUID]["action"]["type"] === "goto") {
                      if (node.data.nodeActions[actionUUID]["action"]["data"]["goto"] !== "") {
                        actionString = `-> k${node.data.nodeActions[actionUUID]["action"]["data"]["goto"]}`
                      }
                    }
                    
                    // If setOutline, assign value to outline var using in-line logic
                    if (node.data.nodeActions[actionUUID]["action"]["type"] === "setOutline") {
                      if (node.data.nodeActions[actionUUID]["action"]["data"]["outline"]["value"] !== "" && node.data.nodeActions[actionUUID]["action"]["data"]["outline"]["inkLabel"] !== "") {
                        actionString = `~ ${node.data.nodeActions[actionUUID]["action"]["data"]["outline"]["inkLabel"]} = "${node.data.nodeActions[actionUUID]["action"]["data"]["outline"]["value"]}"`
                      }
                    }
                    
                    // If setFeedback, set inline and main feedback.
                    if (node.data.nodeActions[actionUUID]["action"]["type"] === "setFeedback") {

                      // Inline feedback
                      let feedbackType = node.data.nodeActions[actionUUID]["action"]["data"]["feedback"]["type"] === "correct" ? "_inlineFeedbackCorrect_" : "_inlineFeedbackIncorrect_"
                      let feedbackContent = cleanString(node.data.nodeActions[actionUUID]["action"]["data"]["feedback"]["content"] === "" ? "Set feedback" : node.data.nodeActions[actionUUID]["action"]["data"]["feedback"]["content"])
                      actionString = `${feedbackType}${feedbackContent}`

                      // Main feedback. This doesn't quite work, because all feedback gets pushed whether or not condition met.
                      if (node.data.nodeActions[actionUUID]["action"]["data"]["feedback"]["type"] === "incorrect") {
                        let mainFeedbackString = `
                        {k${node.id} && (incorrect_points == 0) && ${conditionString}:
                          ${feedbackContent}
                          ~ incorrect_points += 1
                        }
                        `
                        let currentFeedbackText = state.feedbackKnot.data;
                        currentFeedbackText.push({idx: currentFeedbackText.length, source: node.id, edgeID: node.id, text: mainFeedbackString});
                        state.feedbackKnot.data = currentFeedbackText;
                      }
                    }
                    
                    // Add to knot string if not empty
                    if (conditionString !== "" && actionString !== "") {
                      textString = textString + `{${conditionString}:\n${actionString}\n}\n`
                    }
                  }

                };

                // Set a default when empty, otherwise risk compilation fail (no knot content)
                if (textString === "") {
                  textString = "Set scenario actions_narrative_"
                }
                
                // Set default re-direct (pass through)

                const nodeOptions = edges.filter((edge) => edge.source === node.id)
                if (nodeOptions.length > 0) {
                  if (nodeOptions[0].target === '3') {
                    optionString = "-> final_knot"
                  } else {
                    optionString = `-> k${nodeOptions[0].target}\n `
                  }
                }
                
              } else {
                // Handle AI blocks
  
                if (node.data.hasOwnProperty("mode") && node.data.mode === "AIblock") {
                
                  textString = textString + `~ currentFeedbackNode = "k${node.id}AIFeedback" \n`
                  textString = textString + `_AIPromptInput__type_${node.data.nodeAI.type}\n`
                  textString = textString + `_AIPromptInput__blockID_${node.id}\n`

                  if (node.data.nodeAI.type === "explanation") {
                    textString = textString + `_AIPromptInput__topic_${cleanString(node.data.nodeAI.promptInputs.topic)}\n`
                    textString = textString + `_AIPromptInput__userAnalysis_${cleanString(node.data.nodeAI.promptInputs.userAnalysis)}\n`
                    textString = textString + `_AIPromptInput__correctAnalysis_${cleanString(node.data.nodeAI.promptInputs.correctAnalysis)}\n`
                    textString = textString + `_AIPromptInput__correctExplanation_${cleanString(node.data.nodeAI.promptInputs.correctExplanation)}\n`
                    if (node.data.nodeAI.promptInputs?.evaluationInstructions && node.data.nodeAI.promptInputs.evaluationInstructions !== "") {
                      textString = textString + `_AIPromptInput__evaluationInstructions_${cleanString(node.data.nodeAI.promptInputs.evaluationInstructions)}\n`
                    }
                    if (node.data.nodeAI.promptInputs?.feedbackExplanation && node.data.nodeAI.promptInputs.feedbackExplanation !== "") {
                      textString = textString + `_AIPromptInput__feedbackExplanation_${cleanString(node.data.nodeAI.promptInputs.feedbackExplanation)}\n`
                    }
                  };
                  
                  if (node.data.nodeAI.type === "questions") {
                    textString = textString + `_AIPromptInput__topic_${cleanString(node.data.nodeAI.promptInputs.topic)}\n`
                    textString = textString + `_AIPromptInput__topicBackground_${cleanString(node.data.nodeAI.promptInputs.topicBackground)}\n`
                    textString = textString + `_AIPromptInput__exampleQuestions_${cleanString(node.data.nodeAI.promptInputs.exampleQuestions)}\n`
                    if (node.data.nodeAI.promptInputs?.evaluationInstructions && node.data.nodeAI.promptInputs.evaluationInstructions !== "") {
                      textString = textString + `_AIPromptInput__evaluationInstructions_${cleanString(node.data.nodeAI.promptInputs.evaluationInstructions)}\n`
                    }
                  };

                  if (node.data.nodeAI.type === "issuespot") {
                    Object.keys(node.data.nodeAI.issues).forEach((key, idx) => {
                      textString = textString + `_AIPromptInput__issue${idx}__issueName_${cleanString(node.data.nodeAI.issues[key]["issueName"])}\n`
                      textString = textString + `_AIPromptInput__issue${idx}__issueDescription_${cleanString(node.data.nodeAI.issues[key]["issueDescription"])}\n`
                    })
                  };

                  const nodeOptions = edges.filter((edge) => edge.source === node.id)
                  if (nodeOptions.length > 0) {
                    if (nodeOptions[0].target === '3') {
                      optionString = optionString + "-> final_knot"
                    } else {
                      optionString = `* [_textInputOption_] -> k${nodeOptions[0].target}\n `
                    }
                  }
  
                } else {
  
                // Handle normal scenario blocks
  
                Object.keys(node.data.text).forEach((key) => {
                  // Alert handling for empty nodes
                  if (node.data.text[key] === "") {
                    var reflexParsingErrors = state.parsingErrors.reflexErrors
                    reflexParsingErrors.push("Reflex parsing error: empty text block")
                    state.parsingErrors.reflexErrors = reflexParsingErrors
                  }
                  textString = textString + `${cleanString(node.data.text[key])}${node.data.format[key]} \n`
                })
                const nodeOptions = edges.filter((edge) => edge.source === node.id)
                nodeOptions.forEach((option) => {
                  // Setting correct or incorrect without feedback text throws an error
  
                  const targetNode = nodes.filter((node) => node.id === option.target)[0]
                  
                  var scoringString = ""
                  var inlineFeedback = ""

                  if (targetNode.data?.mode === "AIblock") {
                    // Don't score issue spotting blocks (for now)
                    if (targetNode.data.nodeAI.type !== "issuespot") {

                    // Handle AI block feedback

                    if (option.data.feedbackType === "correct") {
                      scoringString = "~ points_received += 1 \n"

                      // If you've marked a route going to an AI block as correct, then get one point for taking that route (total score += 1, points received += 1)
                      // If the AI block evals to correct, you get one more point (total score += 1, points received += 1) - set in setAIDialogue.
                      // So this maintains: you must visit all green routes to get 100%
                      // Picking up points through AI blocks won't let you get 100% if you're missing green routes.

                      scenarioPoints += 1
                      const feedbackString = `
                      {k${option.target}:
                        {k${option.target}AIFeedback}
                      }
                      `

                      // Presentation of AI text and feedback is mainly handled by redux pushing / manipulating scene text / ink variables.

                      let currentFeedbackText = state.feedbackKnot.data;
                      currentFeedbackText.push({idx: option.data?.idx, source: option.source, edgeID: option.id, text: feedbackString});
                      state.feedbackKnot.data = currentFeedbackText;
                    } else {
                    if (option.data.feedbackType === "incorrect") {
                      const feedbackString = `
                      {k${option.target} && incorrect_points == 0:
                        {k${option.target}AIFeedback}
                        ~ incorrect_points += 1
                      }
                      `
                      let currentFeedbackText = state.feedbackKnot.data;
                      currentFeedbackText.push({idx: option.data?.idx, source: option.source, edgeID: option.id, text: feedbackString});
                      state.feedbackKnot.data = currentFeedbackText;
                    } else {

                      // AI blocks will always return an evaluation, so handle this feedback even if correct / incorrect not set in option
                      // Increment incorrect points based on evaluation
                      const feedbackString = `
                      {k${option.target} && incorrect_points == 0:
                        {k${option.target}AIFeedback}
                          {k${option.target}AIEvaluation == "incorrect" || k${option.target}AIEvaluation == "notAppropriate":
                            ~ incorrect_points += 1
                          }
                      }
                      `
                      let currentFeedbackText = state.feedbackKnot.data;
                      currentFeedbackText.push({idx: option.data?.idx, source: option.source, edgeID: option.id, text: feedbackString});
                      state.feedbackKnot.data = currentFeedbackText;

                    }
                    }
                  }
                  } else {
                    if (option.data.feedbackType === "correct") {
                      // scoringString = `
                      // ~ points_received += 1
                      // ~ option${option.id}Feedback = true
                      // `

                      // try altering here - but suspect wouldn't need a lot of other guff too.
                      scoringString = `
                      ~ points_received += 1
                      `
                      scenarioPoints += 1
                      if (option.data.feedbackText === "") {
                        let reflexParsingErrors = state.parsingErrors.reflexErrors
                        reflexParsingErrors.push("Reflex parsing error: empty option feedback")
                        state.parsingErrors.reflexErrors = reflexParsingErrors
                      }
                      const feedbackString = `
                      {option${option.id}Feedback:
                        ${option.data.feedbackText === "" ? "Set feedback for Block ID: "+node.id : cleanString(option.data.feedbackText)}
                      }
                      `
                      const inlineFeedbackString = `_inlineFeedbackCorrect_${option.data.feedbackText === "" ? "Set feedback for Block ID: "+node.id : cleanString(option.data.feedbackText)}`
                      inlineFeedback = inlineFeedbackString
                      var currentFeedbackText = state.feedbackKnot.data;
                      currentFeedbackText.push({idx: option.data?.idx, source: option.source, edgeID: option.id, text: feedbackString});
                      state.feedbackKnot.data = currentFeedbackText;
                    }
                    if (option.data.feedbackType === "incorrect") {
                      scoringString = `
                      ~ option${option.id}Feedback = true
                      `
                      if (option.data.feedbackText === "") {
                        let reflexParsingErrors = state.parsingErrors.reflexErrors
                        reflexParsingErrors.push("Reflex parsing error: empty option feedback")
                        state.parsingErrors.reflexErrors = reflexParsingErrors
                      }
                      const feedbackString = `
                      {option${option.id}Feedback && incorrect_points == 0:
                        ${option.data.feedbackText === "" ? "Set feedback for Block ID: "+node.id : cleanString(option.data.feedbackText)}
                        ~ incorrect_points += 1
                      }
                      `
                      const inlineFeedbackString = `_inlineFeedbackIncorrect_${option.data.feedbackText === "" ? "Set feedback for Block ID: "+node.id : cleanString(option.data.feedbackText)}`
                      inlineFeedback = inlineFeedbackString

                      let currentFeedbackText = state.feedbackKnot.data;
                      currentFeedbackText.push({idx: option.data?.idx, source: option.source, edgeID: option.id, text: feedbackString});
                      state.feedbackKnot.data = currentFeedbackText;
                    }
                  };
  
                  if (option.target === '3') {
                    optionString = optionString + "-> final_knot"
                  } else {
                    if (option.data.format === "passThrough") {
                      optionString = optionString + `-> k${option.target}\n `
                    } else {
                      if (option.data.format === "action") {
                        optionString = optionString + `* {limitToThree()} [${cleanString(option.label)}]${inlineFeedback} \n ${scoringString} -> k${option.target}\n `
                    } else {
                      optionString = optionString + `* {limitToThree()} ${cleanString(option.label)}[]_speechYour_${inlineFeedback} \n ${scoringString} -> k${option.target} \n`
                    }
                  }
                  }}
                )
              };
              };
              
              inkString = inkString + `
              === k${node.id}
              ${textString}
              ${optionString}
              `
            }
          })
          return inkString
        };

        const getFeedback = () => {
          // This aggregates and sorts the feedback to be displayed.
          /*
          During compilation, feedback plus feedback placeholders are extracted from nodes and put in feedbackKnot redux object.
          This is then reduced into the ink feedback knot string.
          This was to enable flexibilty to amend the order in which feedback is displayed. No longer necessary (only first incorrect feedback is displayed), but still convenient.
          AI feedback is dynamically set by targetting relevan ink variables.
          Ink logic determines whether display feedback or whether display maxims (learning notes).
          */
          var feedbackArray = state.feedbackKnot.data
          if (feedbackArray.length > 0) {
            // hope they all have an idx...
            if (feedbackArray[0].hasOwnProperty("idx")) {
              feedbackArray.sort((a, b) => a.idx - b.idx)
              state.feedbackKnot.data = feedbackArray
            }
  
            var textString = ""
            feedbackArray.forEach((feedback) => {
              textString = textString + feedback.text + "\n"
            });
  
            if (textString === "") {
              textString = "Set feedback options in the scenario blocks."
            };
            
            const feedbackString = `
            === feedback
            _feedback_
            ${textString}
            -> DONE
            `
            return(feedbackString)
          } else {
            const feedbackString = `
            === feedback
            _feedback_
            Set feedback options in the scenario blocks.
            -> DONE
            `
            return(feedbackString)
          }

        };

        const getLearningPoints = () => {

          var textString = ""
          Object.keys(state.scenarioProfile.learningNotes["text"]).forEach((key) => {
            textString = textString + state.scenarioProfile.learningNotes.text[key] + "\n"
          });

          if (textString === "") {
            textString = "Set the learning notes in the scenario settings."
          };

          return(`
          === maxims
          _maxims_
          ${textString}
          -> DONE
          `)
        };

        const getFinalKnot = () => {
          return(`
                  === final_knot
                  {points_received == total_points:
                  <-maxims
                  }
                  {points_received != total_points:
                  <-feedback
                  }
                  * [Complete] -> complete
                  === complete
                  -> END
          `)
        };

        const functionString = `
        === function limitToThree()
            ~ return CHOICE_COUNT() < 3
        `

        // Could add a limiting function here. So score is never more than 100% or less than 0%
        // And incorrect options contribute to -ve scoring
        // Transparent scoring is hard....

        const scenarioString = getTitleKnot() + "\n" + getScenarioKnots() + "\n" + getFeedback() + "\n" + getLearningPoints() + "\n" + getFinalKnot();
        const variablesString = getVariables() + "\n"
        inkString = variablesString + scenarioString + functionString;
        state.scenarioProfile.inkString = inkString;
        console.log(inkString)
        // parse errors looks handy. on error too. think about what want to do with them.

        var inkParsingErrors = []
        const errorHandler = (message, type) => {
          inkParsingErrors.push([message, type])
        }
        const compilerOptions = new CompilerOptions(null,[],false, errorHandler, null)
        ink = new Compiler(inkString, compilerOptions).Compile();
        state.parsingErrors.inkErrors = inkParsingErrors
        
        ink.onError = (e) => console.error(e)

        const gameData = gameLoop();
        state.ending = false;
        state.hintVisible = false;
        state.fullText = [];
        state.AIMessageStack = {};
        state.feedbackContent = [];
        state.score = {};
        state.parseChoices = gameData.parseChoices;
        state.sceneText = gameData.sceneText;
        state.tags = gameData.tags;
        state.globals = gameData.globals;
        if (state.scenarioProfile.scenarioAssets.hasOwnProperty("documents")) {
          if (state.scenarioProfile.scenarioAssets.documents[0]["type"] !== "pdf") {
            state.playerDocument = state.scenarioProfile.scenarioAssets.documents[0]["documentData"]
          }
        } else {
          state.playerDocument = [];
        }
    },
    makeEditorChoice: (state, action) => {
        ink.ChooseChoiceIndex(action.payload);
        const gameData = gameLoop();
        let currentText = state.fullText;
        currentText.push(state.sceneText);
        try {
            state.parseChoices = gameData.parseChoices
            state.sceneText = gameData.sceneText
            state.tags = gameData.tags
            state.fullText = currentText
            state.score = state.globals
            state.globals = gameData.globals
            state.ending = gameData.ending
        }
        catch(e) {
            console.log("End of scenario")
            state.ending = gameData.ending
        }
    },
    updateEditorScenarioScore: (state, action) => {
      state.score.total_points = action.payload.total_points;
      state.score.points_recevied = action.payload.points_recevied;
    },
    setEditorFeedbackType: (state, action) => {
      state.feedbackType = action.payload
    },
    setEditorFeedbackContent: (state, action) => {
      // Each call to this is a line of text from the feedback node
      if (!state.feedbackContent.includes(action.payload)) {
        let currentContent = state.feedbackContent;
        currentContent.push(action.payload);
        state.feedbackContent = currentContent
      };
    },
    clearEditorFeedbackContent: (state) => {
      state.feedbackContent = []
    },
    updateNodes: (state, action) => {
      const currentNodes = state.nodes
      const newNodes = applyNodeChanges(action.payload, currentNodes)
      state.nodes = newNodes
    },
    updateEdges: (state, action) => {
      const currentEdges = state.edges
      const newEdges = applyEdgeChanges(action.payload, currentEdges)
      state.edges = newEdges
    },
    updateEdgeTarget: (state, action) => {
      //console.log(action.payload.newConnection)
      const currentEdges = state.edges
      const newEdges = updateEdge(
        action.payload.oldEdge, 
        action.payload.newConnection, 
        currentEdges,
        {shouldReplaceId: false}
      )
      state.edges = newEdges
    },
    addNewEdge: (state, action) => {
      const newEdge = {
        id: state.edgeID.toString(),
        source: action.payload.source,
        target: action.payload.target,
        label: "",
        data: {
          format: "_speechYour_",
          feedbackText: "",
          feedbackType: "neutral"
        },
        className: "normal-edge"
      }
      const currentEdges = state.edges
      const newEdges = addEdge(newEdge, currentEdges)
      state.edges = newEdges
      state.edgeID = state.edgeID + 1
    },
    addNewNodeOnDrag: (state, action) => {
      const position = action.payload.position
      const source = action.payload.source
      const currentNodes = state.nodes
      const currentEdges = state.edges

      const newNode = {
        id: state.nodeID.toString(),
        data: { text: {
                0: ''
              },
               format: {
                0: '_speechOther_'
              }},
        position: position,
        type: "scenarioNode"
      }
      const newEdge = {
        id: state.edgeID.toString(), 
        source: source,
        target: state.nodeID.toString(),
        label: "",
        data: {
          format: "_speechYour_",
          feedbackText: "",
          feedbackType: "neutral"
        },
        className: 'normal-edge'
      }

      const newNodes = currentNodes.concat(newNode)
      const newEdges = addEdge(newEdge, currentEdges)

      state.nodes = newNodes
      state.nodeID = state.nodeID + 1
      state.edges = newEdges
      state.edgeID = state.edgeID + 1
    },
    updateNodeData: (state, action) => {
      const nodeId = action.payload.nodeId
      const data = action.payload.data
      let currentNodes = state.nodes
      const nodeIdx = currentNodes.findIndex((node) => node.id === nodeId)
      
      let currentData = {...currentNodes[nodeIdx]["data"]}

      currentNodes[nodeIdx]["data"] = {...currentData, ...data}
      state.nodes = currentNodes
    },
    updateEdgeData: (state, action) => {
      const newEdges = action.payload
      var currentEdges = state.edges
      Object.keys(newEdges).forEach((newEdge) => {
        const edgeIdx = currentEdges.findIndex((edge) => edge.id === newEdges[newEdge]["id"])
        currentEdges[edgeIdx]["label"] = newEdges[newEdge]["text"]
        currentEdges[edgeIdx]["data"]["format"] = newEdges[newEdge]["format"]
        currentEdges[edgeIdx]["data"]["feedbackText"] = newEdges[newEdge]["feedbackText"]
        currentEdges[edgeIdx]["data"]["feedbackType"] = newEdges[newEdge]["feedbackType"]
        
        if (newEdges[newEdge]["feedbackType"] === "correct") {
          currentEdges[edgeIdx]["style"] = {stroke: "#4caf50"}
        } else {
          if (newEdges[newEdge]["feedbackType"] === "incorrect") {
            currentEdges[edgeIdx]["style"] = {stroke: "#f44336"}
          } else {
            currentEdges[edgeIdx]["style"] = {}
          }
        }
        
      })
      state.edges = currentEdges
    },
    deleteNode: (state, action) => {

      const deletedNodeID = action.payload.deletedNodeID;
      const currentEdges = state.edges;

      // Remove connected edges

      let newEdges = []
      currentEdges.forEach((edge) => {
        if (edge.source !== deletedNodeID && edge.target !== deletedNodeID) {
          newEdges.push(edge)
        } 
      })
      
      // Transform nodes
      const newNodes = state.nodes.map(node => {
        // If the node is an "action" node and refers to the deleted node, reset the "goto" property
        // If the node is conditioned on a (deleted) visited block, reset those properties
        // If the node is conditioned on a (deleted) dynamic block, reset those properties
        if (node.data.mode === "action" && node.data.nodeActions) {
            for (let actionId in node.data.nodeActions) {
                let action = node.data.nodeActions[actionId];
                if (action.action.type === "goto" && action.action.data?.goto === deletedNodeID) {
                    action.action.data.goto = "";
                }
                if (action.condition.type === "visitedBlock" && action.condition.data.visitedBlock?.blockID === deletedNodeID) {
                    action.condition.data.visitedBlock.blockID = "";
                    action.condition.data.visitedBlock.value = "";
                }
                if (action.condition.type === "AIBlockEvaluation" && action.condition.data.AIBlockEvaluation?.blockID === deletedNodeID) {
                    action.condition.data.AIBlockEvaluation.blockID = "";
                    action.condition.data.AIBlockEvaluation.value = "";
                }
            }
        };
        return node;
      }).filter(node => node.id !== deletedNodeID); // Filter out the deleted node

      state.nodes = newNodes
      state.edges = newEdges
    },
    deleteEdge: (state, action) => {
      const currentEdges = state.edges
      const deletedEdgeID = action.payload.id
      const targetNodeID = currentEdges.filter((edge) => edge.id === deletedEdgeID)[0].target
      
      const newEdges = currentEdges.filter((edge) => (edge.id !== deletedEdgeID))

      // Transform nodes
      const newNodes = state.nodes.map(node => {
        // If the edge is connecting an action node to a goto target, reset the goto target
        if (node.data.mode === "action" && node.data.nodeActions) {
            for (let actionId in node.data.nodeActions) {
                let action = node.data.nodeActions[actionId];
                if (action.action.type === "goto" && action.action.data.goto === targetNodeID) {
                    action.action.data.goto = "";
                }
            }
        }
        return node;
      })

      state.edges = newEdges
      state.nodes = newNodes
    },
    setEditable: (state, action) => {
      state.editable = action.payload
    },
    updateScenarioProfile: (state, action) => {
      const currentProfile = state.scenarioProfile
      var newProfile = {...currentProfile, ...action.payload}
      state.scenarioProfile = newProfile
    },
    loadSavedScenario: (state, action) => {
      if (action.payload === "default") {
        state.nodes = defaultScenarioJson.nodes
        state.edges = defaultScenarioJson.edges
        state.edgeID = defaultScenarioJson.edgeID
        state.nodeID = defaultScenarioJson.nodeID
      } else {
        state.nodes = action.payload.nodes
        state.nodeID = action.payload.nodeID
        state.edges = action.payload.edges
        state.edgeID = action.payload.edgeID
      }
    },
    setEditorScenarioUI: (state, action) => {
      const UIState = state.editorScenarioUI;
      const updateUIState = {
        ...UIState,
        ...action.payload
      }
      state.editorScenarioUI = updateUIState
    },
    setScenarioPrimarySourceData: (state, action) => {
      Object.keys(action.payload).forEach((sourceID) => {
        state.scenarioProfile.scenarioAssets.primarySource[sourceID]["xml_string"] = action.payload[sourceID]["xml_string"]
      });
    },
    setEditorScenarioJSONDocumentData: (state, action) => {
        state.scenarioProfile.scenarioAssets.documents[0]["documentData"] = action.payload.documentJSONData;
        state.scenarioProfile.scenarioAssets.documents[0]["goldAnswers"] = action.payload?.goldAnswers;
    },
    setEditorPlayerDocumentData: (state, action) => {
        state.playerDocument = action.payload.documentJSONData;
    },
    resetEditorData: (state) => {
      state.feedbackKnot = defaultScenarioJson.feedbackKnot
      state.nodes = defaultScenarioJson.nodes
      state.edges = defaultScenarioJson.edges
      state.edgeID = defaultScenarioJson.edgeID
      state.nodeID = defaultScenarioJson.nodeID
      state.scenarioProfile = defaultScenarioJson.scenarioProfile
      state.editorScenarioUI = defaultScenarioJson.editorScenarioUI
      state.inkString = defaultScenarioJson.inkString
    },
    setSaveError: (state, action) => {
      state.saveError = action.payload
    },
    setAIDialogue: (state, action) => {
      const AIDialogue = action.payload.dialogueData
      const interactionType = action.payload.interactionType
      const blockID = AIDialogue.blockID
      //console.log(action.payload)

      if (AIDialogue.role === "user") {

        // Update scene text with user input

        let updateSceneText = state.sceneText;
        updateSceneText.push(AIDialogue.content+"_speechYourTextInput_")
        state.sceneText = updateSceneText

        // Add to message stack. Keeping the prompt heading separate like this so can work with the raw content in ink.
        let updateMessageStack = state.AIMessageStack;
        if (updateMessageStack.hasOwnProperty(blockID)) {
          updateMessageStack[blockID].push({
            role:"user",
            content:AIDialogue.promptHeading+AIDialogue.content
          });
        } else {
          updateMessageStack[blockID] = [
            {
              role:"user",
              content:AIDialogue.promptHeading+AIDialogue.content
            }
          ]
        }
        state.AIMessageStack = updateMessageStack;
      };
      if (AIDialogue.role === "assistant") {

        if (AIDialogue.type === "followUp") {

          // Update scene text with assistant input
          let updateSceneText = state.sceneText;
          updateSceneText.push(AIDialogue.content+"_speechOtherTextResponse_");
          state.AIPendingMessage = false;
          state.sceneText = updateSceneText;
  
          // Add to message stack
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;

        } else if (AIDialogue.type === "feedback") {
          const feedbackVAR = ink.variablesState["currentFeedbackNode"]
          const evaluationVAR = `k${blockID}AIEvaluation`

          // Update scene text with inline feedback
          let updateSceneText = state.sceneText;
          let feedbackString = ""

          // Feedback depends on interaction type
          if (interactionType === "explanation") {
            //console.log("setting AI feedback")
            let correctAnalysis = updateSceneText.find((para) => para.startsWith("_AIPromptInput__correctAnalysis_")).slice(32)
            let userAnalysis = updateSceneText.find((para) => para.startsWith("_AIPromptInput__userAnalysis_")).slice(29)
            let correctExplanation = updateSceneText.find((para) => para.startsWith("_AIPromptInput__correctExplanation_")).slice(35)

            // feedback explanation is an optional param
            let feedbackExplanation = ""
            const feedbackExplanationTest = updateSceneText.find((para) => para.startsWith("_AIPromptInput__feedbackExplanation_"))
            if (feedbackExplanationTest) {
              feedbackExplanation = feedbackExplanationTest.slice(36)
            }
            let feedbackType = AIDialogue.evaluation === "correct" ? "_inlineFeedbackAICorrect_" : "_inlineFeedbackAIIncorrect_"

            // provide the feedback explanation if one is given, other
            let finalExplanation = feedbackExplanation === "" ? correctExplanation : feedbackExplanation
  
            feedbackString = feedbackType
            
            // In edge case where got answer right but explanation wrong, be really clear about this. A bit shonky...
            if (AIDialogue.evaluation === "incorrect" && userAnalysis === correctAnalysis) {
              feedbackString = feedbackString + "Right answer, wrong explanation._AIExplanation_"
            }
            feedbackString = feedbackString + correctAnalysis+" "+finalExplanation+"_AIExplanation_"+AIDialogue.content
            updateSceneText.push(feedbackString);
          };
          if (interactionType === "questions") {
            let topicBackground = updateSceneText.filter((para) => para.startsWith("_AIPromptInput__topicBackground_"))[0].slice(32)
            let exampleQuestions = updateSceneText.filter((para) => para.startsWith("_AIPromptInput__exampleQuestions_"))[0].slice(33)
            let feedbackType = AIDialogue.evaluation === "appropriate" ? "_inlineFeedbackAICorrect_" : "_inlineFeedbackAIIncorrect_"
  
            feedbackString = feedbackType

            feedbackString = feedbackString + topicBackground+" Example questions might be: "+exampleQuestions+"_AIExplanation_"+AIDialogue.content
            updateSceneText.push(feedbackString);
          }
          
          // Use similar pattern in the ink feedback knot
          ink.variablesState[feedbackVAR] = feedbackString
          ink.variablesState[evaluationVAR] = AIDialogue.evaluation

          // Add to message stack (for review purposes)
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;

          if (AIDialogue.evaluation === "correct" || AIDialogue.evaluation === "appropriate") {
            const currentPoints = ink.variablesState["points_received"]
            const totalPoints = ink.variablesState["total_points"]
            ink.variablesState["points_received"] = currentPoints + 1
            ink.variablesState["total_points"] = totalPoints + 1
            
          } else {
            // If not correct, still increment total points
            const totalPoints = ink.variablesState["total_points"]
            ink.variablesState["total_points"] = totalPoints + 1
          }
        } else if (AIDialogue.type === "issuespot") {

          // Add to message stack (for review purposes)
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;

          // Set issue evaluations
          Object.keys(AIDialogue.evaluation).forEach((key) => {
            let evaluationVAR = `k${blockID}${key}AIEvaluation`
            let evaluationValue = AIDialogue.evaluation[key] ? "correct" : "incorrect"
            ink.variablesState[evaluationVAR] = evaluationValue
          })
        }
      };
    },
    handlePlayerError: (state, action) => {
      let updateSceneText = state.sceneText;
      updateSceneText.push("An error has occured. Please refresh this page and if the problem persists contact support@legalreflex.com._playerError_")
      state.sceneText = updateSceneText
    },
    setAIPendingMessage: (state, action) => {
      state.AIPendingMessage = action.payload;
    },
    setEditorDocumentEvaluation: (state, action) => {
      action.payload.forEach((node) => {
        ink.variablesState[node.inkLabel] = node.evaluation
      })
    },
    addNodeAction: (state, action) => {
      
      const nodeID = action.payload.nodeID
      const actionUUID = action.payload.actionUUID
      let currentNodes = state.nodes

      const nodeIdx = currentNodes.findIndex((node) => node.id === nodeID)
      let currentNodeData = {...currentNodes[nodeIdx]["data"]}
      let currentActions = currentNodeData.hasOwnProperty("nodeActions") ? currentNodeData.nodeActions : {}

      let updatedActions = {
        ...currentActions,
        [actionUUID]: {
          "condition": {
            "type": "always",
            "data": {}
          },
        "action": {
            "type": "goto",
            "data": {}
          }
        }
      }

      currentNodeData["nodeActions"] = updatedActions
      currentNodes[nodeIdx]["data"] = currentNodeData
      state.nodes = currentNodes
    },
    removeNodeAction: (state, action) => {

      const nodeID = action.payload.nodeID
      const actionUUID = action.payload.actionUUID
      let currentNodes = state.nodes

      const nodeIdx = currentNodes.findIndex((node) => node.id === nodeID)
      let currentNodeData = {...currentNodes[nodeIdx]["data"]}      

      let {[actionUUID]: actionData, ...rest} = currentNodeData.nodeActions
      currentNodeData["nodeActions"] = rest
      currentNodes[nodeIdx]["data"] = currentNodeData
      state.nodes = currentNodes
    },
    setNodeAction: (state, action) => {

      const nodeID = action.payload.nodeID
      const updatedActions = action.payload.nodeActions
      let currentNodes = state.nodes

      const nodeIdx = currentNodes.findIndex((node) => node.id === nodeID)
      let currentNodeData = {...currentNodes[nodeIdx]["data"]}

      currentNodeData["nodeActions"] = updatedActions
      currentNodes[nodeIdx]["data"] = currentNodeData
      state.nodes = currentNodes
    },
    cleanUpNodeActions: (state, action) => {

      // Clean up / reset action nodes that reference other elements of the scenario
      const cleanUpType = action.payload.cleanUpType
      if (cleanUpType === "deleteDocument") {
        
        const newNodes = state.nodes.map(node => {
          // If the node is an "action" node and is conditioned on the deleted document, reset inklabels / values
          if (node.data.mode === "action" && node.data.nodeActions) {
              for (let actionId in node.data.nodeActions) {
                  let action = node.data.nodeActions[actionId];
                  if (action.condition.type === "userDocument") {
                    action.condition.data = {}

                    // To mull whether better to just reset data or to reset individual values.

                      // if (action.condition.data?.redactionDocument) {
                      //   action.condition.data.redactionDocument.inkLabel = ""
                      //   action.condition.data.redactionDocument.value = ""
                      // }
                      // if (action.condition.data?.markupDocument) {
                      //   action.condition.data.markupDocument.inkLabel = ""
                      //   action.condition.data.markupDocument.value = ""
                      // }
                  }
              }
          }
          return node;
        })

        state.nodes = newNodes
      }
      if (cleanUpType === "editDocument") {
        const newInkLabels = action.payload.goldAnswers.map((goldAnswer) => goldAnswer.inkLabel)
        const newNodes = state.nodes.map(node => {
          
          // Check for user document conditions in action nodes
          // If a condition references an inklabel that no longer exists, reset it to empty
          // This is far from perfect since inklabels are just re-assigned in order when setting gold answers
          // But better than nothing and hopefully prevents crashes

          if (node.data.mode === "action" && node.data.nodeActions) {
              for (let actionId in node.data.nodeActions) {
                  let action = node.data.nodeActions[actionId];
                  if (action.condition.type === "userDocument") {
                    if (action.condition.data?.redactionDocument) {
                      if (!newInkLabels.includes(action.condition.data.redactionDocument.inkLabel)) {
                        action.condition.data.redactionDocument.inkLabel = ""
                        action.condition.data.redactionDocument.value = ""
                      }
                    };
                    if (action.condition.data?.markupDocument) {
                      if (!newInkLabels.includes(action.condition.data.markupDocument.inkLabel)) {
                        action.condition.data.markupDocument.inkLabel = ""
                        action.condition.data.markupDocument.value = ""
                      }
                    };
                  }
              }
          }
          return node;
        })
        state.nodes = newNodes
      }
    },
    setNodeAI: (state, action) => {

      const nodeID = action.payload.nodeID
      const nodeAI = action.payload.nodeAI
      let currentNodes = state.nodes

      const nodeIdx = currentNodes.findIndex((node) => node.id === nodeID)
      let currentNodeData = {...currentNodes[nodeIdx]["data"]}

      currentNodeData["nodeAI"] = nodeAI
      currentNodes[nodeIdx]["data"] = currentNodeData
      state.nodes = currentNodes
    },
  },
})

export const { 
loadScenarioIntoInk,
makeEditorChoice,
updateEditorScenarioScore,
setEditorFeedbackType,
setEditorFeedbackContent,
clearEditorFeedbackContent,
updateNodes,
updateEdges,
updateEdgeTarget,
addNewEdge,
addNewNodeOnDrag,
updateNodeData,
updateEdgeData,
deleteNode,
deleteEdge,
setEditable,
updateScenarioProfile,
loadSavedScenario,
setEditorScenarioUI,
setScenarioPrimarySourceData,
setEditorScenarioJSONDocumentData,
setEditorPlayerDocumentData,
resetEditorData,
setSaveError,
setAIDialogue,
handlePlayerError,
setAIPendingMessage,
setEditorDocumentEvaluation,
addNodeAction,
removeNodeAction,
setNodeAction,
cleanUpNodeActions,
setNodeAI,
} = editorSlice.actions

export default editorSlice.reducer