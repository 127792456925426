import { Handle, Position } from 'reactflow';
import { Typography, Grid } from '@mui/material';

const CompleteNode = () => {

  return (
    <>
      <Handle 
        type="target" 
        position={Position.Top} 
        id="a" 
        style={{
          width: 30,
          height: 14,
          borderRadius: 3,
          backgroundColor: "#424242",
        }}
      />
      <Grid container style={{width: 250, height: 50, padding: 10, border: "1px solid #cccccc", borderRadius: 10, backgroundColor: "#ffffff", justifyContent: "center", alignItems: "center"}}>
        <Grid item>
          <Typography variant="body2" color="textSecondary">Complete</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default CompleteNode;