import React, {useState} from 'react';

import { updateNodeData, setEditable, updateScenarioProfile } from './editorSlice';

import { Typography, Grid, IconButton, TextField, Backdrop, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';

import { useDispatch, useSelector } from 'react-redux';

const TitleKnot = (props) => {

    const editable = useSelector((state) => state.editorData.editable)
    const nodeId = props.nodeId;

    const [collapse, setCollapse] = useState(true)
    const titleKnotData = props.data

    const [scenarioTitle, setScenarioTitle] = useState(titleKnotData.scenarioTitle)
    const handleTitleChange = (event) => {
        setScenarioTitle(event.target.value);
    };
    const [scenarioSubTitle, setScenarioSubTitle] = useState(titleKnotData.scenarioSubTitle)
    const handleSubTitleChange = (event) => {
        setScenarioSubTitle(event.target.value);
    };

    const handleCollapse = () => {
        setCollapse(true);
        dispatch(setEditable(true));
        const updateData = {
            nodeId: nodeId,
            data: {
                scenarioTitle: scenarioTitle,
                scenarioSubTitle: scenarioSubTitle
            }
        };
        dispatch(updateNodeData(updateData));
        dispatch(updateScenarioProfile({
            scenarioName: scenarioTitle,
            scenarioShortDescription: scenarioSubTitle,
          }));
    };

    const dispatch = useDispatch();

    return (
      <Grid container style={{width: 250}}>
        <Grid item style={{width: "100%", border: collapse ? "1px solid #cccccc" : "3px solid #3f51b5", borderRadius: 10, padding: collapse ? 10 : 7, backgroundColor: "#ffffff"}}>
            <Grid container style={{width: "100%", justifyContent: "space-between"}}>
            <Typography variant="body2" color="textSecondary">Title</Typography>
            <IconButton disabled={!editable} onClick={() => {setCollapse(false); dispatch(setEditable(false))}}><EditIcon fontSize="small" /></IconButton>
            </Grid>
            <Grid item style={{width: "100%"}}>
            <Typography variant="body2" color="textSecondary">{titleKnotData.scenarioTitle}</Typography>
            <Typography variant="body2" color="textSecondary">{titleKnotData.scenarioSubTitle}</Typography>
            </Grid>
            {collapse ?
            null :
            <Modal
            open={!collapse}
            onClose={() => handleCollapse()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            >
            <Grid item sx={{
                    width: "475px", 
                    padding: "15px",
                    position: "absolute", 
                    top: '30%', 
                    left: '50%', 
                    transform: 'translate(-50%, -30%)',
                    outline: 0,
                    backgroundColor: "#fafafa",
                    borderRadius: "8px"
                }} 
            tabIndex={-1}>
            <Grid container sx={{width: "100%", justifyContent: "space-between"}}>
                <Typography variant="body2" color="textSecondary">Title</Typography>
                <IconButton onClick={() => handleCollapse()} className="nodrag"><DoneIcon fontSize="small"/></IconButton>
            </Grid>
            <Grid item sx={{width: "400px"}}>
            <form autoComplete='off'>
            <TextField
                id="outlined-multiline-static"
                label="Title"
                value={scenarioTitle}
                onChange={handleTitleChange}
                variant="standard"
                fullWidth
                className="nodrag"
            />
            </form>
            </Grid>
            <Grid item sx={{paddingTop: "10px", width: "400px"}}>
            <form autoComplete='off'>
            <TextField
                id="outlined-multiline-static"
                label="Sub-title"
                value={scenarioSubTitle}
                onChange={handleSubTitleChange}
                variant="standard"
                fullWidth
                className="nodrag"
            />
            </form>
            </Grid>
            </Grid>
            </Modal>
            }
        </Grid>
      </Grid>
    )
};
export default TitleKnot;