import { createSlice } from '@reduxjs/toolkit' 

/*

add a current selection concept. what practice area, topic, subtopic, scenario is selected?
make it so carousle button writes topic to current selection
continue learning points to cororate params
make it so learn takes an ID in params (practice area)
populate learn off of this.

*/


export const appDataSlice = createSlice({
  name: "appData",
  initialState: {
    scenarioList: {},
    subTopicList: {},
    topicList: {},
    practiceAreaList: {},
    skillsTree: {},
    achievementsList: {},
    userData: {
      userRoles: [],
    },
    scenarioScore: {
      scenarioID: "",
      scenarioTotalPoints: 0,
      userPoints: 0, 
      scenarioOldELO: 0,
      scenarioNewELO: 0,
      playTime: 0,
      userOldELO: 0,
      userNewELO: 0,
    },
    currentSelection: {
        practiceAreaID: "",
        topicID: "",
        subTopicID: "",
        scenarioID: "",
    },
    navigationDefault: {
      practiceAreaID: "",
      topicID: "",
    },
    accessToken: "",
    appUI: {
      scenarioDetails: false,
      firstTimeLogin: false,
      onBoardIdx: 0,
      achievementHero: {
        achievementID: "",
        displayHero: false,
      },
      displayTopBarHelper: false
    }
  },
  reducers: {
    setAppData: (state, action) => {
      const appData = action.payload;
      state.scenarioList = appData.scenarioList;
      state.subTopicList = appData.subTopicList;
      state.topicList = appData.topicList;
      state.practiceAreaList = appData.practiceAreaList;
      state.skillsTree = appData.skillsTree;
      state.achievementsList = appData.achievementsList;
      state.userData = appData.userData;
      state.navigationDefault = appData.navigationDefault;
      if (appData.userData.firstTimeLogin) {
        state.appUI.firstTimeLogin = true
      };
      if (Object.keys(appData.userData.userScenarios).length === 0) {
        // If haven't played any scenario, set player bar helper to true
        state.appUI.displayTopBarHelper = true
      }
    },
    updateScenarioScore: (state, action) => {
      const scenarioID = action.payload.scenarioID;

      // logic for capturing onboarding scenario in week progress etc.
      if (scenarioID === "onBoardingScenario") {

        state.userData.userScenarios[scenarioID]= {"score": 100}
        state.userData.scenarioCount = state.userData.scenarioCount + 1;
        const timeStamp = Date.now();
        state.userData.userActivity[timeStamp] = {
          "scenarioID": scenarioID,
          "score": 100,
        };

      } else {

      state.scenarioScore.scenarioID = scenarioID;
      state.scenarioScore.subTopicID = action.payload.subTopicID;
      state.scenarioScore.topicID = action.payload.topicID;
      state.scenarioScore.practiceAreaID = action.payload.practiceAreaID;
      state.scenarioScore.scenarioTotalPoints = action.payload.scenarioTotalPoints;
      state.scenarioScore.userPoints = action.payload.userPoints;
      state.scenarioScore.scenarioOldELO = state.scenarioList[scenarioID]["scenarioELO"];
      state.scenarioScore.userOldELO = state.userData.userELO;

      // Calculate new ELOs

      const expectedUser = 1/(1 + 10 ** ((state.scenarioScore.scenarioOldELO - state.scenarioScore.userOldELO)/400)) * state.scenarioScore.scenarioTotalPoints
      const expectedScenario = 1/(1 + 10 ** ((state.scenarioScore.userOldELO - state.scenarioScore.scenarioOldELO)/400)) * state.scenarioScore.scenarioTotalPoints
      const kUser = 16
      const kScenario = 2
      const scenarioScore = state.scenarioScore.scenarioTotalPoints - state.scenarioScore.userPoints
      state.scenarioScore.userNewELO = Math.round(state.scenarioScore.userOldELO + kUser*(state.scenarioScore.userPoints - expectedUser))
      state.scenarioScore.scenarioNewELO = Math.round(state.scenarioScore.scenarioOldELO + kScenario*(scenarioScore - expectedScenario))

      };
    },
    completeScenario: (state, action) => {
      
      // add score to scenario list
      
      const scenarioID = state.scenarioScore.scenarioID;
      const subTopicID = state.scenarioScore.subTopicID;
      const topicID = state.scenarioScore.topicID;
      const practiceAreaID = state.scenarioScore.practiceAreaID;
      const scorePcent = Math.round((state.scenarioScore.userPoints / state.scenarioScore.scenarioTotalPoints) * 100);
      
      state.scenarioList[scenarioID]["userScore"] = scorePcent;

      // add scenario to user scenarios

      if (state.userData.userScenarios.hasOwnProperty(scenarioID)) {
        state.userData.userScenarios[scenarioID]["score"] = scorePcent
      } else {
        state.userData.userScenarios[scenarioID] = {"score": scorePcent, "subTopicID": subTopicID, "topicID": topicID, "practiceAreaID": practiceAreaID}
      };

      // increment scenario count
      state.userData.scenarioCount = state.userData.scenarioCount + 1;

      // set new ELOs

      // add to userActivity

      const timeStamp = action.payload.timeStamp;
      state.userData.userActivity[timeStamp] = {
        "scenarioID": scenarioID,
        "subTopicID": subTopicID,
        "topicID": topicID,
        "practiceAreaID": practiceAreaID,
        "score": scorePcent,
      };

      // calculate and increment week score

      var activityTemp = {}
      var weekScore = 0

      // go through user activity. for activity in the last week, add scores week score. for duplicates, add highest score.

      Object.keys(state.userData.userActivity).forEach((activityTimeStamp) => {
        const activityScenarioID = state.userData.userActivity[activityTimeStamp]["scenarioID"];
        if (activityTimeStamp >= (timeStamp - 7*24*60*60*1000)) {
          if (activityTemp.hasOwnProperty(activityScenarioID)) {
            if (activityTemp[activityScenarioID]["score"] < state.userData.userActivity[activityTimeStamp]["score"]) {
              weekScore -= activityTemp[activityScenarioID]["score"]
              weekScore += state.userData.userActivity[activityTimeStamp]["score"]
              activityTemp[activityScenarioID]["score"] = state.userData.userActivity[activityTimeStamp]["score"]
            }
          } else {
            weekScore += state.userData.userActivity[activityTimeStamp]["score"]
            activityTemp[activityScenarioID] = {
              "score": state.userData.userActivity[activityTimeStamp]["score"]
            }
          }
        }
      });
      state.userData.weekScore = weekScore;

      // write userAchievements

      if (action.payload.achievementsData.newAchievements.length > 0) {
        let currentAchievements = state.userData.userAchievements
        action.payload.achievementsData.newAchievements.forEach((achievementID) => {
          currentAchievements.push(achievementID);
        });
        state.userData.userAchievements = currentAchievements
        state.userData.nextAchievement = action.payload.achievementsData.nextAchievement
        state.appUI.achievementHero.achievementID = action.payload.achievementsData.newAchievements[0]
        state.appUI.achievementHero.displayHero = true
      };

    },
    setCurrentSelection: (state, action) => {
        const selectionType = action.payload.selectionType;
        const selection = action.payload.selection;
        state.currentSelection[selectionType] = selection;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    toggleScenarioDetails: (state) => {
      const currentScenarioDetails = state.appUI.scenarioDetails;
      state.appUI.scenarioDetails = !currentScenarioDetails;
    },
    incrementOnboardIdx: (state) => {
      state.appUI.onBoardIdx = state.appUI.onBoardIdx + 1
    },
    setAchievementHero: (state, action) => {
      state.appUI.achievementHero.displayHero = action.payload
    },
    setFeedbackType: (state, action) => {

      // putting this in app data, but would it make more sense to do it in chocie?

      state.userData.feedbackType = action.payload
    },
    setFeedbackContent: (state, action) => {

      // putting this in app data, but would it make more sense to do it in chocie?
      // if wanted this to persist could write to user scenarios?
      // includes is a work around for arsehole react behaviour in dev (duplicate component loads)

      if (!state.userData.feedbackContent.includes(action.payload)) {
        let currentContent = state.userData.feedbackContent;
        currentContent.push(action.payload);
        state.userData.feedbackContent = currentContent
      };
    },
    clearFeedbackContent: (state) => {
      state.userData.feedbackContent = []
    },
    addLearningTopic: (state, action) => {
      state.userData.userTopic = action.payload
    },
    updateScenarioList: (state, action) => {
      let currentList = state.scenarioList
      const {inkString: inkString, editorData: editorData, ...rest} = action.payload
      const newScenarioData = {
        ...currentList[action.payload.scenarioID],
        ...rest,
      };
      currentList[action.payload.scenarioID] = newScenarioData
      state.scenarioList = currentList
    },
    deleteScenario: (state, action) => {
      var currentScenarios = state.scenarioList
      delete currentScenarios[action.payload]
      state.scenarioList = currentScenarios
    },
    setPrimarySourceData: (state, action) => {
      const scenarioID = action.payload.scenarioID
      Object.keys(action.payload.primarySource).forEach((sourceID) => {
        state.scenarioList[scenarioID]["scenarioAssets"]["primarySource"][sourceID]["xml_string"] = action.payload.primarySource[sourceID]["xml_string"]
      });
    },
    setDisplayTopBarHelper: (state, action) => {
      state.appUI.displayTopBarHelper = action.payload
    },
    updatePracticeNotes: (state, action) => {
      const currentSubTopic = action.payload.currentSubTopic;
      const updateTime = action.payload.updateTime;
      const updatedPracticeNotes = action.payload.content;

      state.subTopicList[currentSubTopic]["subTopicPracticeNotes"]["content"] = updatedPracticeNotes;
      state.subTopicList[currentSubTopic]["subTopicPracticeNotes"]["subTopicUpdatedAt"] = updateTime;
    },
    setScenarioJSONDocumentData: (state, action) => {
      const scenarioID = action.payload.scenarioID
      state.scenarioList[scenarioID]["scenarioAssets"]["documents"][0]["documentData"] = action.payload.documentJSONData;
      state.scenarioList[scenarioID]["scenarioAssets"]["documents"][0]["goldAnswers"] = action.payload?.goldAnswers;
    },
    updateTopicOverview: (state, action) => {
      const currentSubTopic = action.payload.currentSubTopic;
      const updatedTopicOverviewNotes = action.payload.content;
      const updatedSkillLevel = action.payload.skillLevel;
      const updateTime = action.payload.updateTime;
      const updatedReadTime = action.payload.readTime;
      const updatedPlayTime = action.payload.playTime;

      state.subTopicList[currentSubTopic]["subTopicOverviewNotes"] = {
        "content": updatedTopicOverviewNotes,
        "updateTime": updateTime,
      };
      state.subTopicList[currentSubTopic]["skillLevel"] = updatedSkillLevel;
      state.subTopicList[currentSubTopic]["readTime"] = updatedReadTime;
      state.subTopicList[currentSubTopic]["playTime"] = updatedPlayTime;
    },
    setFirstTimeLogin: (state, action) => {
      state.appUI.firstTimeLogin = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  setAppData, 
  setCurrentSelection, 
  setAccessToken, 
  updateScenarioScore, 
  completeScenario, 
  toggleScenarioDetails, 
  incrementOnboardIdx, 
  setAchievementHero,
  setFeedbackType,
  setFeedbackContent,
  clearFeedbackContent,
  addLearningTopic,
  updateScenarioList,
  deleteScenario,
  setPrimarySourceData,
  setDisplayTopBarHelper,
  updatePracticeNotes,
  setScenarioJSONDocumentData,
  updateTopicOverview,
  setFirstTimeLogin
} = appDataSlice.actions

export default appDataSlice.reducer