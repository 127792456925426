import { useState} from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    Grid, 
    Snackbar,
    Alert,
    Stack,
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    } from '@mui/material'
import { loadSavedScenario, updateScenarioProfile, resetEditorData, loadScenarioIntoInk, setEditorScenarioJSONDocumentData, setEditorPlayerDocumentData } from './editorSlice';
import { deleteScenario, updateScenarioList } from '../appData/appDataSlice';
import { useSelector, useDispatch } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

export default function LoadScenario(props) {

    const setSelectedIndex = props.setSelectedIndex;
    const setActiveStep = props.setActiveStep;
    const { user } = useAuth0();
    var navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteScenarioID, setDeleteScenarioID] = useState("");
    const [deleteSuccess, setDeleteSuccess] = useState(false); 

    const setDocumentArray = props.setDocumentArray;
    const setCoverImage = props.setCoverImage;

    const userRoles = useSelector((store) => store.appData.userData.userRoles); 

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleDeleteClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setDeleteSuccess(false);
    };

    const handleDelete = () => {
        
        const deleteScenarioFromDB = async () => {
            // TODO: clean up other assets?
            try {
                const scenarioRequest = {scenarioID: deleteScenarioID}
                const scenarioResponse = await fetch('/api/editor/deletescenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioRequest),
                });
                const deleteData = await scenarioResponse.json()
                if (deleteData.deletedCount > 0) {
                    dispatch(resetEditorData())
                    dispatch(deleteScenario(deleteScenarioID))
                }
                setDeleteModal(false);
                setDeleteSuccess(true)
            }
            catch(e) {console.log(e)}
        };
        deleteScenarioFromDB();
    }

    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const loadList = Object.keys(scenarioList).filter((scenarioID) => {
        // can't edit wiki scenarios
        if(scenarioList[scenarioID].hasOwnProperty("scenarioEditable")) {
            // Admin view
            if (userRoles.includes("read:adminPanel")) {
                return true
            } else {
            // check sharing - if not set, can include
            if (!scenarioList[scenarioID].hasOwnProperty("sharing")) {
                return true
            } else {
                // can view organisation scenario
                if (scenarioList[scenarioID].hasOwnProperty("sharing") && scenarioList[scenarioID]["sharing"] === "openSharing") {
                    return true
                } else {
                    // can view your own scenarios
                    if (scenarioList[scenarioID].hasOwnProperty("author") && scenarioList[scenarioID]["author"]["ID"] === user.sub) {
                        return true
                    } else {
                        return false
                    };
                };
            }
            }
        } else {
            return false
        };
    })

    const userAccessToken = useSelector((state) => state.appData.accessToken);

    const dispatch = useDispatch();

    const handleOpen = async (scenarioID) => {

        const loadScenarioFromDB = async () => {
            const scenarioRequest = {
                scenarioID: scenarioID
            }
            try {
                const scenarioResponse = await fetch('/api/editor/loadScenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioRequest),
                });
                const editorData = await scenarioResponse.json()
                return (editorData)
            } 
            catch(e) {console.log(e)}
        };

        const getDocuments = async (scenarioAssetData) => {

            const docType = scenarioAssetData["type"]
            let endPoint = docType === "pdf" ? '/api/assets/getdocument' : '/api/assets/getjsondocument'
            
            try {
                const documentData = {
                    documentID: scenarioAssetData["documentID"]
                }            
                const dataResponse = await fetch(endPoint, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(documentData),
                });
      
                // handle pdf streaming
      
                if (docType === "pdf") {
                  let result = new Uint8Array(0);
                  const reader = dataResponse.body.getReader();
                  while (true) { // eslint-disable-line no-constant-condition
                      const { done, value } = await reader.read();
                      if (done) {
                          break;
                      }
                      const newResult = new Uint8Array(result.length + value.length);
                      newResult.set(result);
                      newResult.set(value, result.length);
                      result = newResult;
                  }
                  return (result)
                } else {
                  // otherwise assume json doc
                  const responseData = await dataResponse.json()
                  //console.log(responseData)
                  dispatch(setEditorScenarioJSONDocumentData(responseData));
                  dispatch(setEditorPlayerDocumentData(responseData));
                  return ("jsonDocument")
                }
            }
            catch(e) {console.log(e)}
        };
      
        const getCoverImage = async (coverImageID) => {
            try {
                const imageData = {
                    documentID: coverImageID
                }            
                const dataResponse = await fetch('/api/assets/getdocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(imageData),
                });
                let result = new Uint8Array(0);
                const reader = dataResponse.body.getReader();
                while (true) { // eslint-disable-line no-constant-condition
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    const newResult = new Uint8Array(result.length + value.length);
                    newResult.set(result);
                    newResult.set(value, result.length);
                    result = newResult;
                }
                const binString = Array.from(result, (x) => String.fromCodePoint(x)).join("");
                return(btoa(binString))
            }
            catch(e) {console.log(e)}
        };

        const editorData = await loadScenarioFromDB()

        // If have documents, load them
        if (editorData.scenarioAssets.hasOwnProperty("documents")) {
            const scenarioAssetData = editorData.scenarioAssets.documents[0]
            getDocuments(scenarioAssetData).then((result) => setDocumentArray({data: result, documentID: scenarioAssetData["documentID"], type: scenarioAssetData["type"]}));    
        }

        // If have cover image, load it
        if (editorData.scenarioAssets.hasOwnProperty("coverImage")) {
            const coverImageData = editorData.scenarioAssets.coverImage[0]
            getCoverImage(coverImageData["documentID"]).then((result) => setCoverImage({data: result, documentID: coverImageData["documentID"], type: coverImageData["type"]}));
        } else {
            setCoverImage(null);
        }

        // Load the flow data
        dispatch(loadSavedScenario(editorData.hasOwnProperty("editorData") ? editorData.editorData : "default"))

        // Push the scenario profile into editor store
        const profileUpdate = {
            scenarioID: editorData.scenarioID,
            scenarioName: editorData.scenarioName,
            pageID: editorData.pageID,
            scenarioShortDescription: editorData.scenarioShortDescription,
            scenarioLongDescription: editorData.scenarioLongDescription,
            learningNotes: editorData.learningNotes,
            scenarioELO: editorData.scenarioELO,
            scenarioPlayTime: editorData.scenarioPlayTime,
            playCount: editorData.playCount,
            scenarioSubTopics: editorData.scenarioSubTopics,
            scenarioAssets: editorData.scenarioAssets,
            scenarioEditable: true,
            published: editorData.published,
            UIOrder: editorData.UIOrder,
            status: editorData.status,
            sharing: editorData.sharing ? editorData.sharing : "linkSharing",
            RBAC: editorData.RBAC,
            author: editorData.author,
        }
        dispatch(updateScenarioProfile(profileUpdate));

        // Set the UI
        setSelectedIndex(0)
        setActiveStep(4)

        // Compile the scenario
        dispatch(loadScenarioIntoInk())
        
        // Set url nav
        navigate(`/editor/${scenarioID}`);

    };

    const handleCopy = async (scenarioID) => {

        const loadScenarioFromDB = async () => {
            const scenarioRequest = {
                scenarioID: scenarioID
            };
            try {
                const scenarioResponse = await fetch('/api/editor/loadScenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioRequest),
                });
                const editorData = await scenarioResponse.json()

                return (editorData)
            }
            catch(e) {console.log(e)}
        };

        const getAndCopyDocuments = async (docType, copiedFromID, copyDocumentID) => {

            // Could probably create a new api route for this
            
            let endPoint = docType === "pdf" ? '/api/assets/getandcopydocument' : '/api/assets/getandcopyjsondocument'
            try {
                const documentData = {
                    documentID: copiedFromID,
                    copyDocumentID: copyDocumentID
                }

                // Get the doc
                const dataResponse = await fetch(endPoint, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(documentData),
                });
      
                if (docType === "pdf") {
                  let result = new Uint8Array(0);
                  const reader = dataResponse.body.getReader();
                  while (true) { // eslint-disable-line no-constant-condition
                      const { done, value } = await reader.read();
                      if (done) {
                          break;
                      }
                      const newResult = new Uint8Array(result.length + value.length);
                      newResult.set(result);
                      newResult.set(value, result.length);
                      result = newResult;
                  }
                  return (result)
                } else {
                    // Otherwise assume json doc
                    const responseData = await dataResponse.json()
                    //console.log(responseData)
                    dispatch(setEditorScenarioJSONDocumentData(responseData));
                    dispatch(setEditorPlayerDocumentData(responseData));
                  return ("jsonDocument")
                }
            }
            catch(e) {console.log(e)}
        };
      
        const getAndCopyCoverImage = async (copiedFromID, copyDocumentID) => {
            try {
                const imageData = {
                    documentID: copiedFromID,
                    copyDocumentID: copyDocumentID
                }         
                const dataResponse = await fetch('/api/assets/getandcopydocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(imageData),
                });
                let result = new Uint8Array(0);
                const reader = dataResponse.body.getReader();
                while (true) { // eslint-disable-line no-constant-condition
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    const newResult = new Uint8Array(result.length + value.length);
                    newResult.set(result);
                    newResult.set(value, result.length);
                    result = newResult;
                }
                const binString = Array.from(result, (x) => String.fromCodePoint(x)).join("");
                return(btoa(binString))
            }
            catch(e) {console.log(e)}
        };

        const saveScenarioToDB = async (scenarioData) => {
            try {
                const scenarioSaveResponse = await fetch('/api/editor/saveScenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioData),
                });
                const responseData = await scenarioSaveResponse.json()
                if (responseData.modified > 0 || responseData.upsertID !== "") {
                    return true
                }
                } 
            catch(e) {
                console.log(e)
                return false
            }
        };

        let editorData = await loadScenarioFromDB();
        const newUUID = uuidv4();

        dispatch(loadSavedScenario(editorData.hasOwnProperty("editorData") ? editorData.editorData : "default"))

        let profileUpdate = {
            scenarioID: newUUID,
            scenarioName: "Copy - "+ editorData.scenarioName,
            pageID: editorData.pageID,
            scenarioShortDescription: editorData.scenarioShortDescription,
            scenarioLongDescription: editorData.scenarioLongDescription,
            learningNotes: editorData.learningNotes,
            scenarioELO: editorData.scenarioELO,
            scenarioPlayTime: editorData.scenarioPlayTime,
            playCount: editorData.playCount,
            scenarioSubTopics: editorData.scenarioSubTopics,
            scenarioAssets: editorData.scenarioAssets,
            scenarioEditable: true,
            UIOrder: editorData.UIOrder,
            status: "unpublished",
            sharing: "linkSharing",
            RBAC: editorData.RBAC,
            author: {
                ID: user.sub,
                email: user.email
            },
        };

        // If have documents, load and copy them. Loop over keys instead?
        if (editorData.scenarioAssets.hasOwnProperty("documents") || editorData.scenarioAssets.hasOwnProperty("coverImage")) {
            // Handle document copying
            if (editorData.scenarioAssets.hasOwnProperty("documents")) {
                const copiedFromID = editorData.scenarioAssets.documents[0]["documentID"]
                const scenarioAssetData = editorData.scenarioAssets.documents[0]
                const copyDocumentID = uuidv4();
    
                // Update copyDocID in scenario profile
                profileUpdate.scenarioAssets.documents[0] = {
                    ...scenarioAssetData,
                    "documentID": copyDocumentID,
                    "copiedFromID": copiedFromID
                }
    
                getAndCopyDocuments(scenarioAssetData["type"], copiedFromID, copyDocumentID).then(
                    (result) => {
                        setDocumentArray({data: result, documentID: copyDocumentID, type: scenarioAssetData["type"]})
                    }
                )
            };

            // Handle cover image copying
            if (editorData.scenarioAssets.hasOwnProperty("coverImage")) {
                const copiedFromID = editorData.scenarioAssets.coverImage[0]["documentID"]
                const scenarioAssetData = editorData.scenarioAssets.coverImage[0]
                const copyDocumentID = uuidv4();
    
                // Update copyDocID in scenario profile
                profileUpdate.scenarioAssets.coverImage[0] = {
                    ...scenarioAssetData,
                    "documentID": copyDocumentID,
                    "copiedFromID": copiedFromID
                }
    
                getAndCopyCoverImage(copiedFromID, copyDocumentID).then(
                    (result) => {
                        setCoverImage({data: result, documentID: copyDocumentID, type: scenarioAssetData["type"]})
                    }
                )
            };

            dispatch(updateScenarioProfile(profileUpdate));
            } else {
            dispatch(updateScenarioProfile(profileUpdate));
            setCoverImage(null);
        };
        
        // Write copied scenario to redux and set UI

        setSelectedIndex(0)
        setActiveStep(4)
        dispatch(loadScenarioIntoInk())
        dispatch(updateScenarioList({
            ...profileUpdate,
            lastModified: Date.now(),
        }))
        navigate(`/editor/${newUUID}`);

        // Write the copied scenario to DB

        const scenarioData = {
            ...editorData,
            ...profileUpdate,
            lastModified: Date.now(),
        }

        const DBSaveSuccess = saveScenarioToDB(scenarioData)
        // TODO: add save success alert
    };

    const getEditDate = (timestamp) => {
        const JSTime = new Date(timestamp)
        return(JSTime.toDateString())
    }

    return (
        <Grid item sx={{padding: "10px"}}>
            <Grid item sx={{
                maxHeight: `calc(100vh - 90px)`,
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "15px", 
                borderLeft: "1px solid #cccccc66", 
                borderTop: "1px solid #cccccc66", 
                boxShadow: "3px 3px 3px #cccccc",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                width: "20px",
                },
                '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                },
                '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
                },
                '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf"
                },
                }}>
            <Typography variant="h5" paragraph>Load scenario</Typography>
            {loadList.length === 0 ?
            <Typography variant="body2" color="textSecondary">No saved scenarios.</Typography>
            :
            <TableContainer >
                <Table aria-label="scenario table" size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Author</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last updated</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {loadList.map((scenarioID) => { 
                        if (scenarioList.hasOwnProperty(scenarioID)) {
                        return(
                        <TableRow
                        key={scenarioID}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell scope="row">
                            <Typography variant='body2'>{scenarioList[scenarioID]["scenarioName"]}</Typography>
                            <Typography variant='body2' color="textSecondary">{scenarioList[scenarioID]["scenarioShortDescription"]}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='body2'>{scenarioList[scenarioID]["author"]["email"]}</Typography>
                        </TableCell>
                        <TableCell scope="row">
                            {scenarioList[scenarioID]["status"] === "published" ? "Published" : "Not published"}
                        </TableCell>
                        <TableCell scope="row">
                            {getEditDate(scenarioList[scenarioID]["lastModified"])}
                        </TableCell>
                        <TableCell align="right">
                            <Stack direction="row" spacing={0.5}>
                            <Button size="small" variant="outlined" onClick={() => handleOpen(scenarioID)}>Load</Button>
                            <Button size="small" variant="outlined" onClick={() => handleCopy(scenarioID)}>Copy</Button>
                            <Button size="small" color="secondary" variant="outlined" onClick={() => {setDeleteModal(true); setDeleteScenarioID(scenarioID)}}>Delete</Button>
                            </Stack>
                        </TableCell>                        
                        </TableRow>
                    )}})}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        <Dialog
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Confirm scenario deletion:
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" size="small" onClick={handleDeleteModalClose}>Cancel</Button>
            <Button variant="outlined" size="small" color="secondary" onClick={handleDelete} >
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={deleteSuccess} autoHideDuration={6000} onClose={handleDeleteClose} sx={{width: "250px"}}>
            <Alert onClose={handleDeleteClose} variant="filled" severity="success" sx={{width: "100%"}}>
                Scenario deleted.
            </Alert>
        </Snackbar>
        </Grid>
        </Grid>
    );
}