import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Story, Compiler } from "inkjs";
//import defaultStory from "../../game/cc0844e6-44e5-4dac-af11-cef4414e9a31.json";
import defaultStory from "../../game/onboard.json";
import {v4 as uuidv4} from 'uuid';

/*
Rather than bundling all the json game files in the build and having a switch pick them out,
can just write the json as an airtable field and load it on selection. Redux can't handle async logic in normal
reducers so have to use this creatAsyncThunk and extraReducers nonsense. To explore if RTK could simplify this.
Remeber, createAsyncThunk should return a promise.

TODO: why copying all global vars into score?

Might want to be able to load multiple games from local (rather than through api)

*/

export const loadGame = createAsyncThunk(
  // Gets puzzle json (ink) and puzzle assets (another json object)
  // get text from wiki js, pass to loaddata. use const story = new inkjs.Compiler(`Hello World`).Compile();
  'choice/loadData',
  async (loadData) => {
    const userAccessToken = loadData.userAccessToken;
    const scenarioID = loadData.scenarioID;

    // if scenario was made in the editor, get the inkstring from mongo (whilst other ink is still in wiki)  

    if (userAccessToken !== "") {
      const response = await fetch('/api/scenariodata', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({scenarioID: scenarioID}),
      });
      return response.json()
    };
  }
);

export var ink = new Story(defaultStory);

export const gameLoop = () => {
    const sceneText = [];
    let currentTags = [];
     
    while (ink.canContinue) {
      
      sceneText.push(ink.Continue());
      currentTags = currentTags.concat(ink.currentTags);
    }
  
    const { currentChoices, variablesState } = ink;
    const parseChoices = getChoices(currentChoices);
    const gameVars = getGlobalVars(variablesState);
  
    if (!ink.canContinue && !currentChoices.length)
      return {
        tags: {},
        ending: true
      }
    return {
      globals: gameVars,
      tags: getTags(currentTags),
      parseChoices,
      sceneText,
      ending: false
    };
};

export const getGlobalVars = (variablesState) => {
  const globalVars = variablesState._globalVariables
  const gameVars = {};

  // you have to work harder to extract a list from an ink LIST
  // this takes the list item names that are currently "on" and puts them in an array in globals (with the list name as the name)

  const unPackMap = (map, key) => {
    const listVals = []
    map.forEach((val, key) => {
      const item = JSON.parse(key)
      listVals.push(item.itemName)
    })
    gameVars[key] = listVals;
  }

  // origins[0]._itemNameToValues
  // get the variables and put them in an object

  globalVars.forEach(
    (val, key) => {
      if (typeof(val.value) === "object") {
        unPackMap(val.value, key)
      }
      if (typeof(val.value) !== "object") {
        gameVars[key] = val.value}
    }
  )
  return gameVars
  };


export const getTags = tags =>
    tags.reduce(
        (acc, tag) => ({ ...acc, [tag.split(": ")[0]]: tag.split(": ")[1] }),
        {}
);

export const getChoices = choices => 
  choices.reduce(
    (acc, val, idx) => ( [...acc,
        {
      "index": idx,
      "text": val.text
    }]),
    []
  );
    

export const choiceSlice = createSlice({
  name: 'choice',
  initialState: {
    status: "idle",
    score: {},
    ending: false,
    fullText: [],
    AIMessageStack: {},
    playerDocument: [],
    scenarioUI: {
      "viewResources": false,
      "viewHints": false,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
      "selectedDocumentID": "",
      "pendingText": false,
    },
    sessionID: "",
    version: 0,
    ...gameLoop()
  },
  reducers: {
    makeChoice: (state, action) => {
        ink.ChooseChoiceIndex(action.payload);
        const gameData = gameLoop();
        let currentText = state.fullText;
        currentText.push(state.sceneText);
        try {
            state.parseChoices = gameData.parseChoices
            state.sceneText = gameData.sceneText
            state.tags = gameData.tags
            state.fullText = currentText
            state.score = state.globals
            state.globals = gameData.globals
            state.ending = gameData.ending
            if (gameData.sceneText.includes("_setHintsVisible_\n")) {
              state.scenarioUI.viewHints = true
              state.scenarioUI.viewResources = true
            }
            if (gameData.sceneText.includes("_setPrimarySourcesVisible_\n")) {
              state.scenarioUI.viewPrimarySources = true
              state.scenarioUI.viewResources = true
            }
            if (gameData.sceneText.includes("_setDocumentsVisible_\n")) {
              state.scenarioUI.viewDocuments = true
              state.scenarioUI.viewResources = true
            }
            if (gameData.sceneText.includes("_setOutlineVisible_\n")) {
              state.scenarioUI.viewOutline = true
              state.scenarioUI.viewResources = true
            }
        }
        catch(e) {
            console.log("end of story or other error")
            state.ending = gameData.ending
        }
    },
    setScenarioUI: (state, action) => {

      const UIState = state.scenarioUI;
      const updateUIState = {
        ...UIState,
        ...action.payload
      }

      state.scenarioUI = updateUIState
    },
    setAIDialogue: (state, action) => {
      const AIDialogue = action.payload.dialogueData
      const interactionType = action.payload.interactionType
      const blockID = AIDialogue.blockID
      //console.log(AIDialogue)
      if (AIDialogue.role === "user") {

        // Update scene text with user input

        let updateSceneText = state.sceneText;
        updateSceneText.push(AIDialogue.content+"_speechYourTextInput_")
        state.sceneText = updateSceneText

        // Add to message stack
        let updateMessageStack = state.AIMessageStack;
        if (updateMessageStack.hasOwnProperty(blockID)) {
          updateMessageStack[blockID].push({
            role:"user",
            content:AIDialogue.promptHeading+AIDialogue.content
          });
        } else {
          updateMessageStack[blockID] = [
            {
              role:"user",
              content:AIDialogue.promptHeading+AIDialogue.content
            }
          ]
        }
        state.AIMessageStack = updateMessageStack;
      };
      if (AIDialogue.role === "assistant") {

        if (AIDialogue.type === "followUp") {

          // Update scene text with assistant input
          let updateSceneText = state.sceneText;
          updateSceneText.push(AIDialogue.content+"_speechOtherTextResponse_");
          state.sceneText = updateSceneText;

          const UIState = state.scenarioUI;
          const updateUIState = {
            ...UIState,
            pendingText: false
          }
          state.scenarioUI = updateUIState
  
          // Add to message stack
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;

        } else if (AIDialogue.type === "feedback") {
          const feedbackVAR = ink.variablesState["currentFeedbackNode"]
          const evaluationVAR = `k${blockID}AIEvaluation`

          // Update scene text with inline feedback
          let updateSceneText = state.sceneText;
          let feedbackString = ""

          // Feedback depends on interaction type
          if (interactionType === "explanation") {
            //console.log("setting AI feedback")
            let correctAnalysis = updateSceneText.find((para) => para.startsWith("_AIPromptInput__correctAnalysis_")).slice(32)
            let userAnalysis = updateSceneText.find((para) => para.startsWith("_AIPromptInput__userAnalysis_")).slice(29)
            let correctExplanation = updateSceneText.find((para) => para.startsWith("_AIPromptInput__correctExplanation_")).slice(35)

            // feedback explanation is an optional param
            let feedbackExplanation = ""
            const feedbackExplanationTest = updateSceneText.find((para) => para.startsWith("_AIPromptInput__feedbackExplanation_"))
            if (feedbackExplanationTest) {
              feedbackExplanation = feedbackExplanationTest.slice(36)
            }

            let feedbackType = AIDialogue.evaluation === "correct" ? "_inlineFeedbackAICorrect_" : "_inlineFeedbackAIIncorrect_"
            feedbackString = feedbackType

            // provide the feedback explanation if one is given, other
            let finalExplanation = feedbackExplanation === "" ? correctExplanation : feedbackExplanation
            
            // In edge case where got answer right but explanation wrong, be really clear about this. A bit shonky...
            if (AIDialogue.evaluation === "incorrect" && userAnalysis === correctAnalysis) {
              feedbackString = feedbackString + "Right answer, wrong explanation._AIExplanation_"
            }
            feedbackString = feedbackString + correctAnalysis+" "+finalExplanation+"_AIExplanation_"+AIDialogue.content

            // Sigh
            feedbackString = feedbackString + `_AIBlockID_${blockID}`
            updateSceneText.push(feedbackString);
          };
          if (interactionType === "questions") {
            let topicBackground = updateSceneText.filter((para) => para.startsWith("_AIPromptInput__topicBackground_"))[0].slice(32)
            let exampleQuestions = updateSceneText.filter((para) => para.startsWith("_AIPromptInput__exampleQuestions_"))[0].slice(33)
            let feedbackType = AIDialogue.evaluation === "appropriate" ? "_inlineFeedbackAICorrect_" : "_inlineFeedbackAIIncorrect_"
  
            feedbackString = feedbackType

            feedbackString = feedbackString + topicBackground+" Example questions might be: "+exampleQuestions+"_AIExplanation_"+AIDialogue.content
            feedbackString = feedbackString + `_AIBlockID_${blockID}`
            updateSceneText.push(feedbackString);
          }

          // Use similar pattern in the ink feedback knot
          ink.variablesState[feedbackVAR] = feedbackString
          ink.variablesState[evaluationVAR] = AIDialogue.evaluation

          // Add to message stack (for review purposes)
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;
          
          if (AIDialogue.evaluation === "correct" || AIDialogue.evaluation === "appropriate") {
            const currentPoints = ink.variablesState["points_received"]
            const totalPoints = ink.variablesState["total_points"]
            ink.variablesState["points_received"] = currentPoints + 1
            ink.variablesState["total_points"] = totalPoints + 1
          } else {
            const totalPoints = ink.variablesState["total_points"]
            ink.variablesState["total_points"] = totalPoints + 1
          }
        } else if (AIDialogue.type === "issuespot") {
          
          // Add to message stack (for review purposes)
          let updateMessageStack = state.AIMessageStack;
          updateMessageStack[blockID].push(AIDialogue.raw);
          state.AIMessageStack = updateMessageStack;

          // Set issue evaluations
          Object.keys(AIDialogue.evaluation).forEach((key) => {
            let evaluationVAR = `k${blockID}${key}AIEvaluation`
            let evaluationValue = AIDialogue.evaluation[key] ? "correct" : "incorrect"
            ink.variablesState[evaluationVAR] = evaluationValue
          })
        }
      };
    },
    handlePlayerError: (state, action) => {
      let updateSceneText = state.sceneText;
      updateSceneText.push("An error has occured. Please refresh this page and if the problem persists contact support@legalreflex.com._playerError_")
      state.sceneText = updateSceneText
    },
    setPlayerDocumentData: (state, action) => {
      state.playerDocument = action.payload.documentJSONData;
    },
    setDocumentEvaluation: (state, action) => {
      action.payload.forEach((node) => {
        ink.variablesState[node.inkLabel] = node.evaluation
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGame.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadGame.fulfilled, (state, action) => {
        state.status = 'idle';
        const scenario = action.payload["content"]
        state.version = action.payload["version"]
        ink = new Compiler(scenario).Compile();
        const gameData = gameLoop();
        state.ending = false;
        state.hintVisible = false;
        state.fullText = [];
        state.AIMessageStack = {};
        state.AIDialogue = {
          messages: [],
          prompt: "",
        };
        state.score = {};
        state.parseChoices = gameData.parseChoices;
        state.sceneText = gameData.sceneText;
        state.tags = gameData.tags;
        state.globals = gameData.globals;
        state.scenarioUI = {
          "viewResources": false,
          "viewHints": false,
          "viewDocuments": false,
          "viewPrimarySources": false,
          "viewOutline": false,
          "selectedDocumentID": "",
          "pendingText": false,
        };
        state.sessionID = uuidv4()
      });
  },
  });

// Action creators are generated for each case reducer function
export const { makeChoice, setScenarioUI, setAIDialogue, handlePlayerError, setPlayerDocumentData, setDocumentEvaluation } = choiceSlice.actions

export default choiceSlice.reducer